/* Install
expo install expo-av
expo install expo-linear-gradient
expo install expo-keep-awake
yarn add react-native-expo-image-cache
expo install expo-blur
 */

import React from 'react';
import {
	StyleSheet,
	TouchableOpacity,
	View,
	Image,
	Text,
	ImageBackground,
	Dimensions,
	Platform,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { ScrollView } from 'react-native-gesture-handler';
import MiniPlayer from "./MiniPlayer";

// import * as ImageCache from "react-native-expo-image-cache";
// import Menu from './MenuScreen';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

class Icon {
	constructor(module, width, height) {
		this.module = module;
		this.width = width;
		this.height = height;
		Asset.fromModule(this.module).downloadAsync();
	}
}

const { width: DEVICE_WIDTH, height: DEVICE_HEIGHT } = Dimensions.get('window');
const BACKGROUND_COLOR = '#2b1325';
const DISABLED_OPACITY = 0.5;
const FONT_SIZE = 14;
const LOADING_STRING = '... loading ...';
const BUFFERING_STRING = '... buffering ...';
const RATE_SCALE = 3.0;
const VIDEO_CONTAINER_HEIGHT = (DEVICE_HEIGHT * 2.0) / 5.0 - FONT_SIZE * 2;

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			songId: this.props.route.params.songId,
			idArtist: this.props.route.params.idArtist,
			// typePlayer: this.props.route.params.typePlayer,
			currentFeatures: 0,
			isLoading: true,
			features: [],
			// 
			isPlaying: false,
			playbackInstance: null,
			currentIndex: 0,
			volume: 1.0,
			isBuffering: true,
			loadBody: false,
			song: null,
			videoWidth: DEVICE_WIDTH,
			videoHeight: VIDEO_CONTAINER_HEIGHT,
			fontLoaded: true,
			playerMount: false,
		};
	}




	async componentDidUpdate(prevProps, prevState) {
		// console.log('SONG DETAIL UPDATE');
		// console.log('prevState.idArtist: ' + prevState.idArtist);
		// console.log('this.props.route.params.idArtist: ' + this.props.route.params.idArtist);

        // if (prevState.songId) {
            if (prevState.songId !== this.props.route.params.songId) {
                this.setState({
                    songId: this.props.route.params.songId,
                    idArtist: this.props.route.params.idArtist,
                    typePlayer: 'song',
                    isLoading: true,
                    controlsPN: false,
                });
                if (this.state.isLoading === true) {
                    this.fechData(this.state.songId);
                }
			}
			// console.log('entro 3');
			// console.log(this.state.isLoading);
			// console.log('------');
        // }
    }
    
    fechData = async (idDataFech) => {
		const urlFeatures = 'https://backend.nrwmusic.stream/api/' + idDataFech + '/songs';
		const responseFeatures = await fetch(urlFeatures);
		const dataFeatures = await responseFeatures.json();
		this.setState({ features: dataFeatures, isLoading: false });
		// console.log(this.state.features);
		// console.log('entro 1');
		// console.log(this.state.isLoading);
		// console.log('------');

		// album_title: "DRYVE"
		// artist_name: "DRYVE"
		// cover: "//backend.nrwmusic.stream/uploads/images/song-1598536643.jpg"
		// created_at: "2020-08-27 13:57:23"
		// duration: "04:21"
		// id: 17
		// idalbum: 9
		// idartist: 28
		// idgenre: "2"
		// plays: 113
		// popularity: 0
		// title: "Mirror (Shout) [feat. Hunz]"
		// track: 3
		// updated_at: "2020-09-08 13:45:34"
		// urlsong: "urlsong-1598536866.mp3"
		// year: 2020
    };
    
	async componentDidMount() {
        const { songId } = this.state;
		this.fechData(songId);
		// console.log('entro 2');
		// console.log(this.state.isLoading);
		// console.log('------');
	}


		onPressPlayer = () => {
		if (sessionStorage.getItem("playerMount")) {
			this.props.navigation.navigate("Player");
		} else {
			this.props.navigation.navigate("Home");
		}
	};

	render() {

		/////////////////////////// FEATURES - SONG ///////////////////////////
		const renderFeature = this.state.features.map((item) => {

			return (
				<View style={styles.itemsFeature} key={item.id}>


					<View style={styles.videoContainer}>
						<View style={[{width: '100%'}]}>
							<ImageBackground
								resizeMode="contain"
								// source={{ uri: 'https:'+item.cover }}
								// source={require('../assets/images/backCircles.png')}
								style={styles.backCircles}
								blurType="light"
								blurAmount={13}
								blurRadius={15}
							>
								<Image
									style={styles.albumCover}
									source={{ uri: 'https:'+item.cover }}
								/>
							</ImageBackground>
						</View>
					</View>
							
					<View style={styles.playbackContainer}>
						<View style={styles.trackInfo}>
							<Text style={[styles.trackInfoText, styles.largeText]}>
								{item.title}
							</Text>

							<TouchableOpacity
							style={styles.buttonTopAlbum}
							activeOpacity={0.85}
							onPress={() => {
								this.props.navigation.navigate('Tracks', {
									idArtist: item.idartist,
									idAlbum: item.idalbum,
								});
							}}
							>
								<Text style={[styles.trackInfoText, styles.mediumText]}>
									{item.album_title}
								</Text>
							</TouchableOpacity>
										
							<TouchableOpacity
								style={styles.itemsFeature}
								activeOpacity={0.85}
								onPress={() => {
									this.props.navigation.navigate('Artist', {
										idArtist: item.idartist,
									});
								}}
							>
								<Text style={[styles.trackInfoText, styles.smallText]}>
									{item.artist_name}
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<TouchableOpacity
						style={styles.button}
						activeOpacity={0.85}
						onPress={() => {
							this.props.navigation.navigate('Player', {
								idArtist: item.idartist,
								songId: item.id,
								typePlayer: 'song',
								playlistDetail: 'none',
								idAlbum: 0,
							});
						}}
					>
						<Image
							source={require('../assets/images/button-play-now.png')}
							style={styles.buttonimg}
						/>
					</TouchableOpacity>


					{/* <TouchableOpacity
						style={styles.itemsFeature}
						activeOpacity={0.85}
						onPress={() => {
							this.props.navigation.navigate('Player', {
								idArtist: item.idartist,
								songId: item.id,
								typePlayer: 'song',
							});
						}}
					> */}

						
						
						
						{/* <Image style={styles.albumCover} source={{ uri: 'https:'+item.cover }} /> */}
					{/* </TouchableOpacity> */}
					{/* <TouchableOpacity
						style={styles.itemsFeature}
						activeOpacity={0.85}
						onPress={() => {
							this.props.navigation.navigate('Player', {
								idArtist: item.idartist,
								songId: item.id,
								typePlayer: 'song',
							});
						}}
					> */}
						{/* <Text
							numberOfLines={1}
							style={[styles.marginText, styles.mediumText]}
						>
							{item.title}
						</Text> */}
					{/* </TouchableOpacity> */}
					{/* <TouchableOpacity
						style={styles.itemsFeature}
						activeOpacity={0.85}
						onPress={() => {
							this.props.navigation.navigate('Artist', {
								idArtist: item.idartist,
							});
						}}
					> */}
						{/* <Text numberOfLines={1} style={styles.smallText}>
							{item.artist_name}
						</Text> */}
					{/* </TouchableOpacity> */}
				</View>
			);
		});
		/////////////////////////// FEATURES - SONG ///////////////////////////



		return !this.state.fontLoaded ? (
			<LinearGradient colors={["#130810", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#130810"]} style={styles.linearGradient}>
				<View style={styles.loading}>
					<Image style={[styles.loadingGif]} source={require("../assets/images/loading-nrw.gif")} />
				</View>
			</LinearGradient>
		) : (
			<LinearGradient colors={["#130810", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#130810"]} style={styles.linearGradient}>
				<View style={styles.container}>
					<View style={styles.nameContainer}>
						<TouchableOpacity
							// onPress={() => this.props.navigation.goBack(null)}
							onPress={() => {
								this.props.navigation.navigate("Artist", {
									idArtist: this.state.idArtist,
								});
							}}
							style={styles.btnContain}
							activeOpacity={0.85}
						>
							<Image style={[styles.btnImage]} source={require("../assets/images/icon-page-back.png")} />
						</TouchableOpacity>
					</View>
					<ImageBackground resizeMode="cover" source={require("../assets/images/backCircles.png")} style={styles.backColor} blurType="light" blurRadius={35}>
						{renderFeature}
					</ImageBackground>
				</View>

				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}
				<MiniPlayer />
				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}

				{/* /////////////////////////// MENU /////////////////////////// */}
				<View style={styles.menuContain}>
					<TouchableOpacity onPress={() => this.props.navigation.navigate("Home")} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-home.png")} />
						<Text style={[styles.menuText]}>Home</Text>
					</TouchableOpacity>

					<TouchableOpacity onPress={() => this.onPressPlayer()} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-player.png")} />
						<Text style={[styles.menuText]}>Player</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("Library");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-library.png")} />
						<Text style={[styles.menuText]}>Library</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("About");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-about.png")} />
						<Text style={[styles.menuText]}>About</Text>
					</TouchableOpacity>
				</View>
				{/* /////////////////////////// MENU /////////////////////////// */}
			</LinearGradient>
		);
		

		

		// return (
		// 	<LinearGradient
		// 		colors={['#130810', '#2b1325', '#2b1325', '#2b1325', '#2b1325', '#2b1325', '#130810']}
		// 		style={styles.linearGradient}
		// 	>
		// 		<View style={styles.statusBarColor}></View>
		// 		<ScrollView
		// 			style={styles.container}
		// 			vertical={true}
		// 			showsHorizontalScrollIndicator={false}
		// 			scrollEventThrottle={200}
		// 			decelerationRate="fast"
		// 		>
		// 			<View style={styles.container}>
		// 				{this.state.isLoading || !this.state.features ? (
		// 					<View style={styles.loading}>
		// 						<Image
		// 							style={[styles.loadingGif]}
		// 							source={require('../assets/images/loading-nrw.gif')}
		// 						/>
		// 					</View>
		// 				) : (
		// 					<Text></Text>
		// 				)}						

		// 				{/******************** FEATURES SECTION ********************/}
		// 				<ImageBackground
		// 					resizeMode="contain"
		// 					source={require('../assets/images/backColor.png')}
		// 					style={styles.backColor}
		// 				>
		// 					<View style={styles.alignSection}>
		// 						<Image
		// 							style={[styles.titleFeaturedTracks, styles.titlesSection]}
		// 							source={require('../assets/images/title-songs.png')}
		// 						/>
		// 						<TouchableOpacity
		// 							onPress={() => {
		// 								this.props.navigation.navigate('ListSong');
		// 							}}
		// 							style={styles.btnContain}
		// 							activeOpacity={0.85}
		// 						>
		// 							<Image
		// 								source={require('../assets/images/btn-see-all.png')}
		// 								style={styles.btnImage}
		// 							/>
		// 						</TouchableOpacity>
		// 					</View>
		// 					<View
		// 						style={styles.containerSong}
		// 					>
		// 						{renderFeature}
		// 					</View>
		// 				</ImageBackground>
		// 				{/******************** FEATURES SECTION ********************/}

						
		// 			</View>
		// 		</ScrollView>

		// 		{/* /////////////////////////// MENU /////////////////////////// */}
		// 		<View style={styles.menuContain}>
		// 			<TouchableOpacity
		// 				onPress={() => this.props.navigation.navigate('Home')}
		// 				style={styles.menuBtnContain}
		// 				activeOpacity={0.85}
		// 			>
		// 				<Image
		// 					style={[styles.menuIconStyle]}
		// 					source={require('../assets/images/icon-home.png')}
		// 				/>
		// 				<Text style={[styles.menuText]}>Home</Text>
		// 			</TouchableOpacity>

		// 			<TouchableOpacity
		// 				onPress={() => {
		// 					this.props.navigation.navigate('Player');
		// 				}}
		// 				style={styles.menuBtnContain}
		// 				activeOpacity={0.85}
		// 			>
		// 				<Image
		// 					style={[styles.menuIconStyle]}
		// 					source={require('../assets/images/icon-player.png')}
		// 				/>
		// 				<Text style={[styles.menuText]}>Player</Text>
		// 			</TouchableOpacity>

		// 			<TouchableOpacity
		// 				onPress={() => {
		// 					this.props.navigation.navigate('Library');
		// 				}}
		// 				style={styles.menuBtnContain}
		// 				activeOpacity={0.85}
		// 			>
		// 				<Image
		// 					style={[styles.menuIconStyle]}
		// 					source={require('../assets/images/icon-library.png')}
		// 				/>
		// 				<Text style={[styles.menuText]}>Library</Text>
		// 			</TouchableOpacity>
					
		// 			<TouchableOpacity
		// 				onPress={() => {
		// 					this.props.navigation.navigate('About');
		// 				}}
		// 				style={styles.menuBtnContain}
		// 				activeOpacity={0.85}
		// 			>
		// 				<Image
		// 					style={[styles.menuIconStyle]}
		// 					source={require('../assets/images/icon-about.png')}
		// 				/>
		// 				<Text style={[styles.menuText]}>About</Text>
		// 			</TouchableOpacity>
		// 		</View>
        //         {/* /////////////////////////// MENU /////////////////////////// */}
		// 	</LinearGradient>
		// );
	}
}

const styles = StyleSheet.create({
	/////////////// STATUS BAR ///////////////
	statusBarColor: {
		position: 'absolute',
		top: 0,
		width: '100%',
		// height: Platform.OS === 'ios' ? 21 : 0,
		...Platform.select({
			ios: {
				height: 21
			},
			android: {
				height: 0
			},
			default: {
			  height: 0
			}
		}),
		
		backgroundColor: '#190914',
		zIndex: 999,
	},
	/////////////// STATUS BAR ///////////////
	/////////////// LOADING ///////////////
	loadingGif: {
		width: 80,
		height: 12,
	},
	loading: {
		flex: 1,
		width: windowWidth,
		height: windowHeight,
		alignItems: 'center',
		justifyContent: 'center',
	},
	/////////////// LOADING ///////////////
	
	/////////////// MENU ///////////////
	menuText: {
		marginTop: 8,
		textAlign: 'center',
		fontSize: 9,
		fontFamily: 'Muli_400Regular',
		color: '#fff',
	},
	menuIconStyle: {
		height: 30,
		width: 30,
	},
	menuContain: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		borderWidth: 0,
		borderColor: '#22051a',
		backgroundColor: '#22051a',
	},
	menuBtnContain: {
		width: 30,
		marginTop: 8,
		marginBottom: 8,
	},
	menuBtnImage: {
		width: '100%',
		height: 30,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
	},
	alignMenu: {
		flex: 1,
		borderWidth: 0,
		borderColor: '#22051a',
		width: '100%',
		padding: 8,
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		margin: 0,
	},
	/////////////// MENU ///////////////

	
	//

	linearGradient: {
		flex: 1,
		width: '100%',
		height: '100%',
	},
	// backColor: {
	// 	width: '100%',
	// 	paddingTop: 10,
	// 	flex: 1,
	// 	justifyContent: 'center',
	// 	alignItems: 'center',
	// },
	// container: {
	// 	flex: 1,
	// 	width: '100%',
	// 	// height: windowHeight,
	// },
	containerSong: {
		flex: 1,
		width: '100%',
		marginTop: 20,
		justifyContent: 'center',
		alignItems: 'center',
	},
	scrollHorizontal: {
		marginLeft: 13,
	},
	marginBottom: {
		marginBottom: 30,
	},
	titlesSection: {
		marginTop: 10,
		resizeMode: 'contain',
	},
	titleFeaturedTracks: {
		width: 100,
		height: 25,
	},
	itemsFeature: {
		width: '100%',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	albumCover: {
		width: 300,
		height: 300,
		borderRadius: 10,
		// resizeMode: "cover",
	},
	titleExplorer: {
		width: 120,
		height: 30,
	},
	explorerFullWidth: {
		width: '100%',
	},
	albumExplorerCover: {
		width: 125,
		height: 125,
		borderRadius: 10,
		resizeMode: 'cover',
	},
	itemsExplorer: {
		width: 125,
		// marginRight: 10,
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	titleWeeklyChart: {
		width: 180,
		height: 30,
	},
	titleLastALbums: {
		width: 180,
		height: 30,
	},
	albumWeeklyChartCover: {
		width: 60,
		height: 60,
		borderRadius: 10,
		marginTop: 10,
		marginBottom: 10,
		resizeMode: 'cover',
	},
	itemsWeeklyChart: {
		width: '100%',
		paddingLeft: 5,
		paddingRight: 15,
	},
	borderWeeklyChart: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		borderTopWidth: 1,
		borderBottomWidth: 1,
		borderStyle: 'solid',
		borderTopColor: '#281a24',
		borderBottomColor: '#281a24',
	},
	textWeeklyChart: {
		width: '60%',
	},
	btnWeeklyChart: {
		width: 40,
		height: 40,
	},
	marginText: {
		marginTop: 18,
		marginBottom: 8,
	},
	mediumText: {
		// height: 30,
		textAlign: 'left',
		color: '#fff',
		fontSize: 18,
		fontFamily: 'Muli_700Bold',
	},
	smallText: {
		// height: 20,
		textAlign: 'left',
		fontSize: 14,
		fontFamily: 'Muli_400Regular',
		color: '#abaaaa',
	},
	textNumber: {
		// height: 20,
		textAlign: 'center',
		fontSize: 14,
		fontFamily: 'Muli_400Regular',
		color: '#f155e4',
	},
	alignSection: {
		padding: 13,
		marginBottom: 11,
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	btnContain: {
		width: '30%',
		height: 25,
		marginTop: 10,
		// backgroundPosition: 'left center',
	},
	btnImage: {
		width: '100%',
		height: 25,
		marginTop: 5,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
	},
	//
	//
	//
	//
	//

	//
	linearGradient: {
		// flex: 1,
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		flex: 1,
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	backColor: {
		zIndex: -1,
		width: '100%',
		// height: '50%',
		// minWidth: DEVICE_WIDTH / 1.5,
		// maxWidth: DEVICE_WIDTH / 1.5,
		minHeight: DEVICE_HEIGHT - 130,
		maxHeight: DEVICE_HEIGHT - 130,
		flex: 1,
		top: 0,
		alignItems: 'center',
		// flexDirection: 'column',
		justifyContent: 'center',
		// justifyContent: 'flex-start',
	},



	backCircles: {
		width: '100%',
		height: 250,
		// minHeight: DEVICE_WIDTH / 1.4,
		maxHeight: DEVICE_WIDTH / 1.6,
		top: 0,
		justifyContent: 'center',
		alignItems: 'center',
		// borderRadius: '100%',
	},
	albumCover: {
		width: 250,
		height: 250,
		// minWidth: DEVICE_WIDTH / 2.0,
		maxWidth: DEVICE_WIDTH / 1.6,
		// minHeight: DEVICE_WIDTH / 2.0,
		maxHeight: DEVICE_WIDTH / 1.6,
		// borderRadius: '100%',
		borderRadius: 10,
	},
	trackInfo: {
		marginTop: 20,
		marginBottom: 10,
		paddingLeft: 20,
		paddingRight: 20,
		// height: 70,
		width: '100%',
	},

	trackInfoText: {
		textAlign: 'center',
		flexWrap: 'wrap',
		color: '#fff',
		fontFamily: 'Muli_700Bold',
	},
	//
	largeText: {
		fontSize: 22,
		// minHeight: 26,
		fontFamily: 'Muli_900Black',
		color: '#fff',
	},
	mediumText: {
		fontSize: 18,
		minHeight: 22,
		marginTop: 5,
		marginBottom: 8,
		fontFamily: 'Muli_700Bold',
		color: '#fff',
	},
	smallText: {
		fontSize: 14,
		// minHeight: 18,
		fontFamily: 'Muli_400Regular',
		color: '#abaaaa',
	},

	IconStyle: {
		padding: 10,
		margin: 5,
		marginTop: 40,
		height: 15,
		width: 25,
		resizeMode: 'stretch',
	},
	PlayIconStyle: {
		padding: 5,
		margin: 0,
		height: 75,
		width: 75,
		resizeMode: 'stretch',
	},

	controls: {
		flexDirection: 'row',
	},
	//
	titlesSection: {
		zIndex: 10,
		padding: 13,
		width: '100%',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		alignSelf: 'flex-start',
	},

	btnContain: {
		width: 40,
		height: 40,
		marginTop: 20,
		marginLeft: 20,
		justifyContent: 'flex-start',
	},

	btnImage: {
		width: '100%',
		height: 40,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
		marginRight: 20,
	},
	// ///////////////////////////////////
	// ///////////////////////////////////

	// /////////// ICONS///////////////
	classPlay: {
		width: 75,
		height: 75,
		margin: 5,
	},
	classPause: {
		width: 60,
		height: 60,
		margin: 5,
	},
	classStop: {
		width: 30,
		height: 30,
		margin: 5,
	},
	classNext: {
		width: 30,
		height: 30,
		margin: 5,
	},
	classPrev: {
		width: 30,
		height: 30,
		margin: 5,
	},

	classLoop: {
		width: 30,
		height: 30,
		marginTop: 5,
		marginBottom: 5,
		marginLeft: 15,
		marginRight: 5,
	},

	classMute: {
		width: 30,
		height: 30,
		margin: 5,
	},

	classTrack: {
		width: 166,
		height: 5,
	},

	classThumb: {
		width: 18,
		height: 22,
	},

	// ///////////////////////////////////

	emptyContainer: {
		alignSelf: 'stretch',
		backgroundColor: BACKGROUND_COLOR,
	},
	container: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignSelf: 'stretch',
	},
	wrapper: {},
	nameContainer: {
		height: 60,
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	videoContainer: {
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		// marginTop: 40,
	},
	video: {
		maxWidth: 0,
	},
	playbackContainer: {
		width: '100%',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		// minHeight: 70,
	},
	playbackSlider: {
		alignSelf: 'stretch',
	},
	timestampRow: {
		// flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		alignSelf: 'stretch',
		minHeight: FONT_SIZE + 15,
		width: '100%',
		// paddingLeft: 20,
		// paddingRight: 20,
	},
	text: {
		fontSize: FONT_SIZE,
		minHeight: FONT_SIZE + 15,
		fontFamily: 'Muli_400Regular',
		color: '#ABAAAA',
	},
	buffering: {
		textAlign: 'left',
		marginTop: 20,

		// paddingLeft: 20,
		minWidth: DEVICE_WIDTH / 2.3,
		maxWidth: DEVICE_WIDTH / 2.3,
	},
	timestamp: {
		textAlign: 'right',
		marginTop: 20,

		// paddingRight: 20,
		minWidth: DEVICE_WIDTH / 2.3,
		maxWidth: DEVICE_WIDTH / 2.3,
	},
	button: {
		// backgroundColor: BACKGROUND_COLOR,
		//   borderWidth: 1,
		//   borderColor: "black",
		//   borderStyle: "solid",
		width: 70,
		height: 20,
	},
	buttonsContainerBase: {
		// flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 20,
		paddingRight: 20,
	},
	buttonsContainerTopRow: {
		// backgroundColor: 'red',

		
		marginBottom: 10,
		// maxHeight: ICON_PLAY_BUTTON.height,
		// minWidth: DEVICE_WIDTH / 2.0,
		// maxWidth: DEVICE_WIDTH / 2.0
		minWidth: DEVICE_WIDTH,
		maxWidth: DEVICE_WIDTH,
		paddingLeft: 20,
		paddingRight: 20,
	},
	buttonsContainerMiddleRow: {
		// maxHeight: ICON_MUTED_BUTTON.height,
		// flex: 1,
		alignSelf: 'stretch',
		paddingLeft: 20,
		paddingRight: 20,
		maxHeight: 75,
		marginTop: -50,
		marginBottom: -10,
		width: '100%',
		// backgroundColor: 'red',
		//
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	volumeContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: 10,
		// paddingRight: 20,
		// paddingLeft: 20,
		// minWidth: DEVICE_WIDTH / 2.0,
		// maxWidth: DEVICE_WIDTH / 2.0,
	},
	volumeSlider: {
		width: '70%',
		// width: DEVICE_WIDTH / 2.0 - ICON_MUTED_BUTTON.width,
	},
	buttonsContainerBottomRow: {
		// maxHeight: ICON_THUMB_1.height,
		alignSelf: 'stretch',
		paddingRight: 20,
		paddingLeft: 20,
	},
	rateSlider: {
		width: DEVICE_WIDTH / 2.0,
	},
	buttonsContainerTextRow: {
		maxHeight: FONT_SIZE,
		alignItems: 'center',
		paddingRight: 20,
		paddingLeft: 20,
		minWidth: DEVICE_WIDTH,
		maxWidth: DEVICE_WIDTH,
	},
	button: {
		marginTop: 10,
		width: 124,
		height: 35,
	},
	buttonimg: {
		width: 124,
		height: 35,
		resizeMode: 'contain',
	},
});
