/* Install
expo install expo-av
expo install expo-linear-gradient
expo install expo-keep-awake
yarn add react-native-expo-image-cache
expo install expo-blur
 */

import React from 'react';
import {
	StyleSheet,
	TouchableOpacity,
	View,
	Image,
	Text,
	ImageBackground,
	Dimensions,
	Platform,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { ScrollView } from 'react-native-gesture-handler';
// import * as ImageCache from "react-native-expo-image-cache";
// import Menu from './MenuScreen';
import MiniPlayer from "./MiniPlayer";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


export default class App extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		currentFeatures: 0,
		isLoading: true,
		features: [],
		explorer: [],
		weeklyChart: [],
		lastAlbums: [],
		//
		isPlaying: false,
		playbackInstance: null,
		currentIndex: 0,
		volume: 1.0,
		isBuffering: true,
		isLoading: true,
		loadBody: false,
		song: null,
		playerMount: false,
	};

	async componentDidMount() {
		const urllastAlbums = 'https://backend.nrwmusic.stream/api/songs';
		const responselastAlbums = await fetch(urllastAlbums);
		const datalastAlbums = await responselastAlbums.json();
		this.setState({ lastAlbums: datalastAlbums, isLoading: false });
	}

		onPressPlayer = () => {
		if (sessionStorage.getItem("playerMount")) {
			this.props.navigation.navigate("Player");
		} else {
			this.props.navigation.navigate("Home");
		}
	};

	render() {
	

		/////////////////////////// LAST ALBUMS ///////////////////////////
		const renderLastALbums = this.state.lastAlbums.map((item) => {
			return (
				<TouchableOpacity
					style={styles.itemsWeeklyChart}
					activeOpacity={0.85}
					key={item.id}
					onPress={() => {
						this.props.navigation.navigate('SongDetail', {
							songId: item.id,
							idArtist: item.idartist,
						});
					}}
				>
					
					<View style={styles.borderWeeklyChart}>
						<Image
							style={styles.albumWeeklyChartCover}
							source={{ uri: 'https:'+item.cover }}
						/>
						<View style={styles.textWeeklyChart}>
							<Text numberOfLines={1} style={styles.mediumText}>
								{item.title}
							</Text>
							{/* <TouchableOpacity
								style={styles.explorerFullWidth}
								activeOpacity={0.85}
								onPress={() => {
									this.props.navigation.navigate('Artist', {
										idArtist: item.idartist,
									});
								}}
							> */}
								<Text numberOfLines={1} style={styles.smallText}>
									{item.artist_name}
								</Text>
							{/* </TouchableOpacity> */}
						</View>
						<View style={styles.btnWeeklyChart}>
							<Image
								source={require('../assets/images/icon-page-next.png')}
								style={styles.btnWeeklyChart}
							/>
						</View>
					</View>
				</TouchableOpacity>
			);
		});
		/////////////////////////// LAST ALBUMS ///////////////////////////

		// let loadBody;
		// if (this.state.isLoading || !this.state.features) { this.state.loadBody = this.setState({ loadBody: true }); }
		// else { this.state.loadBody = this.setState({ loadBody: false });  }

		return (
			<LinearGradient colors={["#130810", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#130810"]} style={styles.linearGradient}>
				<View style={styles.statusBarColor}></View>
				<ScrollView style={styles.container} vertical={true} showsHorizontalScrollIndicator={false} scrollEventThrottle={200} decelerationRate="fast">
					<View style={styles.container}>
						{this.state.isLoading || !this.state.features ? (
							<View style={styles.loading}>
								<Image style={[styles.loadingGif]} source={require("../assets/images/loading-nrw.gif")} />
							</View>
						) : (
							<Text></Text>
						)}

						{/******************** LAST ALBUMS SECTION ********************/}
						<View style={styles.alignSection}>
							<Image style={[styles.titleLastALbums, styles.titlesSection]} source={require("../assets/images/title-songs.png")} />
							<TouchableOpacity
								style={styles.btnContain}
								activeOpacity={0.85}
								onPress={() => {
									this.props.navigation.navigate("Player", {
										playlistDetail: "songs",
										typePlayer: "playlist",
										idArtist: 0,
										songId: 0,
										idAlbum: 0,
									});
								}}
							>
								<Image style={[styles.btnImage]} source={require("../assets/images/button-play-now.png")} />
								{/* <Image
									style={[styles.btnImage]}
									source={require('../assets/images/icon-page-back.png')}
								/> */}
							</TouchableOpacity>
						</View>
						<View style={[styles.marginBottom, styles.scrollHorizontal]}>{renderLastALbums}</View>
						{/******************** LAST ALBUMS SECTION ********************/}
					</View>
				</ScrollView>

				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}
				<MiniPlayer />
				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}
				
				{/* /////////////////////////// MENU /////////////////////////// */}
				<View style={styles.menuContain}>
					<TouchableOpacity onPress={() => this.props.navigation.navigate("Home")} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-home.png")} />
						<Text style={[styles.menuText]}>Home</Text>
					</TouchableOpacity>

					<TouchableOpacity onPress={() => this.onPressPlayer()} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-player.png")} />
						<Text style={[styles.menuText]}>Player</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("Library");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-library.png")} />
						<Text style={[styles.menuText]}>Library</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("About");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-about.png")} />
						<Text style={[styles.menuText]}>About</Text>
					</TouchableOpacity>
				</View>
				{/* /////////////////////////// MENU /////////////////////////// */}
			</LinearGradient>
		);
	}
}

const styles = StyleSheet.create({
	/////////////// STATUS BAR ///////////////
	statusBarColor: {
		position: 'absolute',
		top: 0,
		width: '100%',
		// height: Platform.OS === 'ios' ? 21 : 0,
		...Platform.select({
			ios: {
				height: 21
			},
			android: {
				height: 0
			},
			default: {
			  height: 0
			}
		}),
		
		backgroundColor: '#190914',
		zIndex: 999,
	},
	/////////////// STATUS BAR ///////////////
	/////////////// LOADING ///////////////
	loadingGif: {
		width: 80,
		height: 12,
	},
	loading: {
		flex: 1,
		width: windowWidth,
		height: windowHeight,
		alignItems: 'center',
		justifyContent: 'center',
	},
	/////////////// LOADING ///////////////
	
	/////////////// MENU ///////////////
	menuText: {
		marginTop: 8,
		textAlign: 'center',
		fontSize: 9,
		fontFamily: 'Muli_400Regular',
		color: '#fff',
	},
	menuIconStyle: {
		height: 30,
		width: 30,
	},
	menuContain: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		borderWidth: 0,
		borderColor: '#22051a',
		backgroundColor: '#22051a',
	},
	menuBtnContain: {
		width: 30,
		marginTop: 8,
		marginBottom: 8,
	},
	menuBtnImage: {
		width: '100%',
		height: 30,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
	},
	alignMenu: {
		flex: 1,
		borderWidth: 0,
		borderColor: '#22051a',
		width: '100%',
		padding: 8,
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		margin: 0,
	},
	/////////////// MENU ///////////////

	
	//

	linearGradient: {
		flex: 1,
		width: '100%',
		height: '100%',
	},
	backColor: {
		width: '100%',
		paddingTop: 10,
	},
	container: {
		flex: 1,
		width: '100%',
		// height: windowHeight,
	},
	scrollHorizontal: {
		marginLeft: 13,
	},
	marginBottom: {
		marginBottom: 30,
	},
	titlesSection: {
		marginTop: 10,
		resizeMode: 'contain',
	},
	titleFeaturedTracks: {
		width: 200,
		height: 25,
	},
	itemsFeature: {
		width: 260,
		marginRight: 10,
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	albumCover: {
		width: 230,
		height: 230,
		borderRadius: 10,
		// resizeMode: "cover",
	},
	titleExplorer: {
		width: 120,
		height: 30,
	},
	explorerFullWidth: {
		width: '100%',
	},
	albumExplorerCover: {
		width: 125,
		height: 125,
		borderRadius: 10,
		resizeMode: 'cover',
	},
	itemsExplorer: {
		width: 125,
		marginRight: 10,
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	titleWeeklyChart: {
		width: 180,
		height: 30,
	},
	titleLastALbums: {
		width: 80,
		height: 30,
	},
	albumWeeklyChartCover: {
		width: 60,
		height: 60,
		borderRadius: 10,
		marginTop: 10,
		marginBottom: 10,
		resizeMode: 'cover',
	},
	itemsWeeklyChart: {
		width: '100%',
		paddingLeft: 5,
		paddingRight: 15,
	},
	borderWeeklyChart: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		borderTopWidth: 1,
		borderBottomWidth: 1,
		borderStyle: 'solid',
		borderTopColor: '#281a24',
		borderBottomColor: '#281a24',
	},
	textWeeklyChart: {
		width: '60%',
	},
	btnWeeklyChart: {
		width: 40,
		height: 40,
	},
	marginText: {
		marginTop: 18,
		marginBottom: 8,
	},
	mediumText: {
		height: 30,
		textAlign: 'left',
		color: '#fff',
		fontSize: 18,
		fontFamily: 'Muli_700Bold',
	},
	smallText: {
		height: 20,
		textAlign: 'left',
		fontSize: 14,
		fontFamily: 'Muli_400Regular',
		color: '#abaaaa',
	},
	textNumber: {
		height: 20,
		textAlign: 'center',
		fontSize: 14,
		fontFamily: 'Muli_400Regular',
		color: '#f155e4',
	},
	alignSection: {
		padding: 13,
		marginBottom: 11,
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	btnContain: {
		width: 100,
		height: 30,
		marginTop: 12,
		// backgroundPosition: 'left center',
	},
	btnImage: {
		width: '100%',
		height: 30,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
		// backgroundPosition: 'center',
	},
});
