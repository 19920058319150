import * as Linking from "expo-linking";

export default {
	prefixes: [Linking.makeUrl("/")],
	config: {
		Root: {
			path: "root",
			screens: {
				Home: "HomeScreen",
				About: "AboutScreen",
				Links: "LinksScreen",
				Artist: "ArtistScreen",
				Player: "PlayerScreen",
				Tracks: "TracksScreen",
				SongDetail: "SongDetailScreen",
				Features: "FeaturesScreen",
				Explorer: "ExplorerScreen",
				Library: "LibraryScreen",
				ListAlbum: "ListAlbumScreen",
				ListSong: "ListSongScreen",
				MiniPlayer: "MiniPlayer",
			},
		},
	},
};
