import React, { useState } from "react";
import { Dimensions, StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
const { width: DEVICE_WIDTH, height: DEVICE_HEIGHT } = Dimensions.get("window");

// const MiniPlayer = (props) => {
export default class MiniPlayer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			songName: null,
			albumName: null,
			artistName: null,
			coverName: null,
			isSongPlaying: false,
			imgIcon: "icon.jpg",
		};
	}

	componentDidMount() {
		this.setState({
			songName: sessionStorage.getItem("songName"),
			albumName: sessionStorage.getItem("albumName"),
			artistName: sessionStorage.getItem("artistName"),
			coverName: sessionStorage.getItem("coverName"),
			isSongPlaying: sessionStorage.getItem("isSongPlaying"),
			imgIcon: sessionStorage.getItem("imgIcon"),
		});

		this.timeout = setInterval(() => {
			this.setState({
				songName: sessionStorage.getItem("songName"),
				albumName: sessionStorage.getItem("albumName"),
				artistName: sessionStorage.getItem("artistName"),
				coverName: sessionStorage.getItem("coverName"),
				isSongPlaying: sessionStorage.getItem("isSongPlaying"),
				imgIcon: sessionStorage.getItem("imgIcon"),
			});
		}, 10000);
	}

	render() {
		/* /////////////////////////// MINI PLAYER /////////////////////////// */
		return this.state.isSongPlaying ? (
			// <TouchableOpacity style={styles.miniplayerContain} onPress={() => this.props.navigation.navigate("Player")} activeOpacity={0.85}>
			<View style={styles.miniplayerContain}>
				<Image
					style={styles.miniplayerCover}
					source={{
						uri: this.state.coverName,
					}}
				/>
				<View style={styles.miniplayerTextCont}>
					<Text style={[styles.miniplayerText1]} numberOfLines={1}>
						{this.state.songName}
					</Text>
					<Text style={[styles.miniplayerText3]} numberOfLines={1}>
						{this.state.artistName}
					</Text>
				</View>
				<Image style={styles.miniplayerIcon} source={require("../assets/images/" + this.state.imgIcon)} />
			</View>
		) : (
			<View />
		);
		/* /////////////////////////// MINI PLAYER /////////////////////////// */
	}
}

// export default MiniPlayer;

const styles = StyleSheet.create({
	/////////////// MINI PLAYER ///////////////
	miniplayerContain: {
		width: "100%",
		flexDirection: "row",
		// alignItems: "flex-end",
		// justifyContent: "space-around",
		backgroundColor: "#22051a",
		borderBottomColor: "#420b33",
		borderBottomWidth: 1,
		margin: 0,
		padding: 0,
	},
	miniplayerCover: {
		borderWidth: 0,
		width: 60,
		height: 60,
		margin: 0,
		padding: 0,
	},
	miniplayerIcon: {
		borderWidth: 0,
		width: 40,
		height: 40,
		marginTop: 10,
		marginBottom: 5,
		padding: 0,
	},
	miniplayerTextCont: {
		flexDirection: "column",
		minWidth: DEVICE_WIDTH - 120,
		maxWidth: DEVICE_WIDTH - 120,
	},
	miniplayerText1: {
		marginTop: 12,
		paddingLeft: 20,
		paddingRight: 20,
		textAlign: "left",
		fontSize: 14,
		fontFamily: "Muli_400Regular",
		color: "#fff",
	},
	miniplayerText2: {
		marginTop: 10,
		paddingLeft: 20,
		paddingRight: 20,
		textAlign: "left",
		fontSize: 13,
		fontFamily: "Muli_700Bold",
		color: "#fff",
	},
	miniplayerText3: {
		marginBottom: 5,
		marginTop: 5,
		paddingLeft: 20,
		paddingRight: 20,
		textAlign: "left",
		fontSize: 9,
		fontFamily: "Muli_700Bold",
		color: "#fff",
	},
	/////////////// MINI PLAYER ///////////////
});
