import React, { Component } from "react";
import { StyleSheet, TouchableOpacity, View, Image, Text, ImageBackground, Dimensions, Platform } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { ScrollView } from "react-native-gesture-handler";
import MiniPlayer from "./MiniPlayer";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

export default class App extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		idArtist: this.props.route.params.idArtist,
		idAlbum: this.props.route.params.idAlbum,
		currentIndex: 0,
		isLoading: true,
		artist: null,
		songsartists: null,
		topSongs: [],
		playerMount: false,
		// topAlbums: [],
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.idAlbum !== this.props.route.params.idAlbum) {
			this.setState({ idAlbum: this.props.route.params.idAlbum, isLoading: true });
			if (this.state.isLoading === true) {
				this.fechArtist(this.state.idAlbum);
			}
		}
	}

	async fechArtist(idAlbum) {
		// const url = 'https://backend.nrwmusic.stream/api/' + idArtist + '/artists';
		// const response = await fetch(url);
		// const data = await response.json();
		// this.setState({ artist: data, isLoading: false });

		const urlTopSongs = "https://backend.nrwmusic.stream/api/" + idAlbum + "/tracksalbums";

		const responseTopSongs = await fetch(urlTopSongs);
		const dataTopSongs = await responseTopSongs.json();
		this.setState({ topSongs: dataTopSongs });
		console.log(this.state.topSongs);

		const urlTopAlbums = "https://backend.nrwmusic.stream/api/" + idAlbum + "/albums";
		const responseTopAlbums = await fetch(urlTopAlbums);
		const dataTopAlbums = await responseTopAlbums.json();
		this.setState({ topAlbums: dataTopAlbums, isLoading: false });
		console.log(this.state.topAlbums);
	}

	async componentDidMount() {
		this.fechArtist(this.state.idAlbum);
		// console.log(this.state.idAlbum);
	}

	renderBannerCover() {
		return this.state.topAlbums ? (
			<ImageBackground resizeMode="cover" source={{ uri: "https:" + this.state.topAlbums[0].cover }} style={styles.backbanner}>
				<LinearGradient colors={["black", "rgba(0, 0, 0, 0)"]} style={styles.bannerGradient}>
					<View style={styles.backAlignSection}>
						<TouchableOpacity
							// onPress={() => this.props.navigation.goBack()}
							onPress={() => {
								this.props.navigation.navigate("Artist", {
									idArtist: this.state.idArtist,
								});
							}}
							style={styles.btnContain}
							activeOpacity={0.85}
						>
							<Image style={[styles.btnImage]} source={require("../assets/images/icon-page-back.png")} />
						</TouchableOpacity>
					</View>
					<Text style={[styles.nameArtist, styles.largeText]}>{this.state.topAlbums[0].title}</Text>
				</LinearGradient>
			</ImageBackground>
		) : null;
	}

	onPressPlayer = () => {
		if (sessionStorage.getItem("playerMount")) {
			this.props.navigation.navigate("Player");
		} else {
			this.props.navigation.navigate("Home");
		}
	};

	render() {
		/////////////////////////// TOP SONGS ///////////////////////////
		const renderTopSongs = this.state.topSongs.map((item) => {
			return (
				<TouchableOpacity
					style={styles.itemsTopSongs}
					key={item.id}
					onPress={() => {
						this.props.navigation.navigate("SongDetail", {
							idArtist: item.idartist,
							songId: item.id,
							typePlayer: "song",
							playlistDetail: "none",
							idAlbum: item.idalbum,
						});
					}}
				>
					<View style={styles.borderTopSongs}>
						<Image style={styles.albumTopSongsCover} source={{ uri: "https:" + item.cover }} />
						<View style={styles.textTopSongs}>
							<Text numberOfLines={1} style={styles.mediumTextTS}>
								{item.title}
							</Text>
							<Text numberOfLines={1} style={styles.smallTextTS}>
								{item.artist_name}
							</Text>
						</View>
						<View style={styles.btnTopSongs}>
							<Image source={require("../assets/images/icon-page-next.png")} style={styles.btnTopSongs} />
						</View>
					</View>
				</TouchableOpacity>
			);
		});
		/////////////////////////// TOP SONGS ///////////////////////////

		return (
			<LinearGradient colors={["#130810", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#130810"]} style={styles.linearGradient}>
				<View style={styles.statusBarColor}></View>
				<ScrollView style={styles.scrollVertical} vertical={true} showsHorizontalScrollIndicator={false} scrollEventThrottle={200} decelerationRate="fast">
					<ImageBackground resizeMode="cover" source={require("../assets/images/backColor.png")} style={styles.backColor}>
						<View style={styles.container}>
							{this.state.isLoading || !this.state.topAlbums ? (
								<View style={styles.loading}>
									<Image style={[styles.loadingGif]} source={require("../assets/images/loading-nrw.gif")} />
								</View>
							) : (
								<Text></Text>
							)}

							{this.renderBannerCover()}

							{/******************** TOP SONGS ********************/}
							<View style={styles.alignSection}>
								<Image style={[styles.titleAlbums, styles.titlesSection]} source={require("../assets/images/title-songs.png")} />
								<TouchableOpacity
									style={styles.btnContainPlay}
									activeOpacity={0.85}
									onPress={() => {
										this.props.navigation.navigate("Player", {
											playlistDetail: "none",
											typePlayer: "album",
											idArtist: this.state.idArtist,
											songId: 0,
											idAlbum: this.state.idAlbum,
										});
									}}
								>
									<Image style={[styles.btnImagePlay]} source={require("../assets/images/button-play-now.png")} />
								</TouchableOpacity>
							</View>
							<View style={[styles.marginBottom, styles.scrollHorizontal]}>{renderTopSongs}</View>
							{/******************** TOP SONGS ********************/}
						</View>
					</ImageBackground>
				</ScrollView>

				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}
				<MiniPlayer />
				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}

				{/* /////////////////////////// MENU /////////////////////////// */}

				<View style={styles.menuContain}>
					<TouchableOpacity onPress={() => this.props.navigation.navigate("Home")} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-home.png")} />
						<Text style={[styles.menuText]}>Home</Text>
					</TouchableOpacity>

					<TouchableOpacity onPress={() => this.onPressPlayer()} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-player.png")} />
						<Text style={[styles.menuText]}>Player</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("Library");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-library.png")} />
						<Text style={[styles.menuText]}>Library</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("About");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-about.png")} />
						<Text style={[styles.menuText]}>About</Text>
					</TouchableOpacity>
				</View>
				{/* /////////////////////////// MENU /////////////////////////// */}
			</LinearGradient>
		);
	}
}

const styles = StyleSheet.create({
	/////////////// STATUS BAR ///////////////
	statusBarColor: {
		position: "absolute",
		top: 0,
		width: "100%",
		// height: Platform.OS === 'ios' ? 21 : 0,
		...Platform.select({
			ios: {
				height: 21,
			},
			android: {
				height: 0,
			},
			default: {
				height: 0,
			},
		}),
		backgroundColor: "#190914",
		zIndex: 999,
	},
	/////////////// STATUS BAR ///////////////
	/////////////// LOADING ///////////////
	loadingGif: {
		width: 80,
		height: 12,
	},
	loading: {
		flex: 1,
		width: windowWidth,
		height: windowHeight,
		alignItems: "center",
		justifyContent: "center",
	},
	/////////////// LOADING ///////////////
	backAlignSection: {
		// position: 'absolute',
		// top: 0,
		paddingLeft: 13,
		marginTop: -35,
		marginBottom: 30,
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-between",
		zIndex: 99,
	},
	btnContain: {
		width: 30,
		height: 30,
		marginTop: 20,
		marginLeft: 10,
		marginBottom: 10,
		justifyContent: "flex-start",
		zIndex: 9,
	},
	btnImage: {
		width: "100%",
		height: 30,
		alignItems: "center",
		justifyContent: "flex-end",
		resizeMode: "contain",
	},
	btnContainPlay: {
		width: 100,
		height: 30,
		marginTop: 10,
		marginLeft: 10,
		marginBottom: 10,
		justifyContent: "flex-start",
		zIndex: 9,
	},
	btnImagePlay: {
		width: "100%",
		height: 30,
		alignItems: "center",
		justifyContent: "flex-end",
		resizeMode: "contain",
	},

	/////////////// MENU ///////////////
	menuText: {
		marginTop: 8,
		textAlign: "center",
		fontSize: 9,
		fontFamily: "Muli_400Regular",
		color: "#fff",
	},
	menuIconStyle: {
		height: 30,
		width: 30,
	},
	menuContain: {
		width: "100%",
		flexDirection: "row",
		alignItems: "flex-end",
		justifyContent: "space-around",
		borderWidth: 0,
		borderColor: "#22051a",
		backgroundColor: "#22051a",
	},
	menuBtnContain: {
		width: 30,
		marginTop: 8,
		marginBottom: 8,
	},
	menuBtnImage: {
		width: "100%",
		height: 30,
		alignItems: "center",
		justifyContent: "flex-end",
		resizeMode: "contain",
	},
	alignMenu: {
		flex: 1,
		borderWidth: 0,
		borderColor: "#22051a",
		width: "100%",
		padding: 8,
		flexDirection: "row",
		alignItems: "flex-end",
		justifyContent: "space-around",
		margin: 0,
	},
	/////////////// MENU ///////////////

	//
	bannerGradient: {
		flex: 1,
		top: 0,
		width: "100%",
		height: 80,
		alignItems: "center",
		justifyContent: "center",
	},
	scrollVertical: {
		flex: 1,
		width: "100%",
	},
	linearGradient: {
		height: "100%",
		flex: 1,
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		flex: 1,
		width: "100%",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	buttoncont: {
		marginTop: -30,
		marginBottom: 20,
		width: "fit-content",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
	},
	button: {
		width: 124,
		height: 35,
	},
	buttonimg: {
		width: 124,
		height: 35,
		resizeMode: "contain",
	},
	titlesSection: {
		marginTop: 10,
		resizeMode: "contain",
	},
	backColor: {
		width: "100%",
		flex: 1,
		top: 0,
		alignItems: "center",
		justifyContent: "flex-start",
	},
	backbanner: {
		width: "100%",
		height: 250,
		top: -30,
		justifyContent: "flex-start",
		alignItems: "center",
	},
	backprofile: {
		width: 110,
		height: 110,
		top: -85,
		marginBottom: -40,
		justifyContent: "center",
		alignItems: "center",
	},
	backprofilecont: {
		width: 80,
		height: 80,
		borderRadius: 300,
	},
	backCircles: {
		width: "100%",
		flex: 1,
		top: 0,
		justifyContent: "center",
		alignItems: "center",
	},
	bannerCover: {
		width: "100%",
		height: 250,
	},
	albumCover: {
		width: 220,
		height: 220,
		borderRadius: 300,
	},
	trackInfo: {
		// padding: 20,
	},
	trackInfoText: {
		textAlign: "center",
		flexWrap: "wrap",
		color: "#fff",
		fontFamily: "Muli_700Bold",
	},
	nameArtist: {
		textAlign: "center",
		flexWrap: "wrap",
		color: "red",
		// marginTop: -80,
		fontFamily: "Muli_700Bold",
	},

	control: {
		margin: 20,
	},
	IconStyle: {
		padding: 10,
		margin: 5,
		marginTop: 40,
		height: 15,
		width: 25,
		resizeMode: "stretch",
	},
	PlayIconStyle: {
		padding: 5,
		margin: 0,
		height: 95,
		width: 95,
		resizeMode: "stretch",
	},
	controls: {
		flexDirection: "row",
	},
	//////////// TOP SONGS ////////////
	alignSection: {
		paddingTop: 0,
		paddingLeft: 13,
		paddingRight: 13,
		paddingBottom: 13,
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	textNumber: {
		height: 20,
		textAlign: "center",
		fontSize: 14,
		fontFamily: "Muli_400Regular",
		color: "#f155e4",
	},
	scrollHorizontal: {
		width: "100%",
		marginLeft: 13,
	},
	titleTopSongs: {
		width: 160,
		height: 30,
		// marginTop: 10,
		// marginBottom: 10,
	},
	titleAlbums: {
		width: 85,
		height: 30,
		// marginTop: 10,
		// marginBottom: 10,
	},
	albumTopSongsCover: {
		width: 60,
		height: 60,
		borderRadius: 10,
		marginTop: 10,
		marginBottom: 10,
		resizeMode: "cover",
	},
	itemsTopSongs: {
		width: "100%",
		paddingLeft: 5,
		paddingRight: 15,
	},
	borderTopSongs: {
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
		borderTopWidth: 0,
		borderBottomWidth: 1,
		borderStyle: "solid",
		borderTopColor: "rgba(255, 255, 255, 0)",
		borderBottomColor: "rgba(255, 255, 255, 0.05)",
	},
	textTopSongs: {
		width: "60%",
	},

	btnTopSongs: {
		width: 40,
		height: 40,
	},

	mediumTextTS: {
		height: 30,
		textAlign: "left",
		color: "#fff",
		fontSize: 18,
		fontFamily: "Muli_700Bold",
	},
	smallTextTS: {
		height: 20,
		textAlign: "left",
		fontSize: 14,
		fontFamily: "Muli_400Regular",
		color: "#abaaaa",
	},
	largeText: {
		fontSize: 24,
		fontFamily: "Muli_900Black",
		color: "#fff",
	},
	mediumText: {
		height: 30,
		textAlign: "center",
		color: "#fff",
		fontSize: 18,
		fontFamily: "Muli_700Bold",
	},
	smallText: {
		height: 20,
		textAlign: "center",
		fontSize: 14,
		fontFamily: "Muli_400Regular",
		color: "#abaaaa",
	},

	//////////// ALBUM ////////////
	scrollHorizontalAlbum: {
		width: "100%",
		flex: 1,
		// paddingLeft: 13,
		// paddingRight: 13,
		marginBottom: 50,
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
	},
	itemsTopAlbum: {
		// display: 'block',
		width: "46%",
		// flex: 1,
		paddingLeft: "2%",
		paddingRight: "2%",
		// flexDirection: 'row',
		// alignItems: 'center',
		// justifyContent: 'space-between',
	},
	buttonTopAlbum: {
		// float: 'left',
		width: "100%",
		flex: 1,
		// alignItems: 'center',
		// justifyContent: 'space-between',
		// flexDirection: 'row',
	},
	noBorderTopSongs: {
		// float: 'left',
		width: "100%",
		// flex: 1,
		alignItems: "center",
		// justifyContent: 'space-between',
		flexDirection: "column",
	},
	albumTopCover: {
		width: "100%",
		height: 160,
		marginTop: 10,
		marginBottom: 10,
		resizeMode: "cover",
		borderRadius: 10,
	},
	textTopAlbums: {
		// float: 'left',
		width: "100%",
		// flex: 1,
	},
	//////////// ALBUM ////////////
});
