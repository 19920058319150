import React, { useEffect } from "react";
import { Dimensions, Image, StyleSheet, Text, View, ImageBackground, TouchableOpacity } from "react-native";
import { Asset } from "expo-asset";
import { Audio, Video } from "expo-av";
import Slider from "@react-native-community/slider";
import { LinearGradient } from "expo-linear-gradient";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

class Icon {
	constructor(module, width, height) {
		this.module = module;
		this.width = width;
		this.height = height;
		Asset.fromModule(this.module).downloadAsync();
	}
}

const ICON_PLAY_BUTTON = new Icon(require("../assets/images/player-icons/icon-play.png"), 34, 51);
const ICON_LOOP_ALL_BUTTON = new Icon(require("../assets/images/player-icons/icon-loop.png"), 77, 35);
const ICON_LOOP_ONE_BUTTON = new Icon(require("../assets/images/player-icons/icon-loop-one.png"), 77, 35);
const ICON_MUTED_BUTTON = new Icon(require("../assets/images/player-icons/icon-muted.png"), 67, 58);
const ICON_THUMB_1 = new Icon(require("../assets/images/player/thumb_1.png"), 18, 19);

const LOOPING_TYPE_ALL = 0;
const LOOPING_TYPE_ONE = 1;
const LOOPING_TYPE_ICONS = { 0: ICON_LOOP_ALL_BUTTON, 1: ICON_LOOP_ONE_BUTTON };

const { width: DEVICE_WIDTH, height: DEVICE_HEIGHT } = Dimensions.get("window");
const BACKGROUND_COLOR = "#2b1325";
const DISABLED_OPACITY = 0.5;
const FONT_SIZE = 14;
const LOADING_STRING = "... loading ...";
const BUFFERING_STRING = "... buffering ...";
const RATE_SCALE = 3.0;
const VIDEO_CONTAINER_HEIGHT = (DEVICE_HEIGHT * 2.0) / 5.0 - FONT_SIZE * 2;

export default class App extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.index = 0;
		this.isSeeking = false;
		this.shouldPlayAtEndOfSeek = false;
		this.playbackInstance = null;
		this.state = {
			idArtist: this.props.route.params.idArtist,
			songId: this.props.route.params.songId,
			idAlbum: this.props.route.params.idAlbum,
			typePlayer: this.props.route.params.typePlayer,
			playlistDetail: this.props.route.params.playlistDetail,
			//
			playerMount: false,
			imgIcon: "icon.jpg",
			isSongPlaying: false,
			isPlaying: false,
			playbackInstance: null,
			currentIndex: 0,
			volume: 0.0,
			isBuffering: true,
			isLoading: true,
			song: null,
			controlsPN: false,
			//
			showVideo: false,
			playbackInstanceName: LOADING_STRING,
			loopingType: LOOPING_TYPE_ALL,
			muted: false,
			playbackInstancePosition: null,
			playbackInstanceDuration: null,
			shouldPlay: false,
			showVolume: true,
			phoneHeight: windowHeight,
			// isPlaying: false,
			// isBuffering: false,
			// isLoading: true,
			fontLoaded: true,
			shouldCorrectPitch: true,

			// volume: 1.0,
			rate: 1.0,
			videoWidth: DEVICE_WIDTH,
			videoHeight: VIDEO_CONTAINER_HEIGHT,
			poster: false,
			useNativeControls: false,
			fullscreen: false,
			throughEarpiece: false,
		};
	}

	async componentWillUnmount() {
		this._isMounted = false;
	}

	async componentDidUpdate(prevProps, prevState) {
		// console.log('cargo 1');
		// console.log('this.props.route.params.typePlayer: ' + this.props.route.params.typePlayer);

		if (this.props.route.params.typePlayer === "artist") {
			// console.log('prevState.idArtist: ' + prevState.idArtist);
			// console.log('this.props.route.params.idArtist: ' + this.props.route.params.idArtist);

			// if (prevState.idArtist) {
			if (prevState.idArtist !== this.props.route.params.idArtist) {
				this.setState({
					idArtist: this.props.route.params.idArtist,
					typePlayer: "artist",
					isLoading: true,
					controlsPN: true,
				});

				// console.log('idArtist: ' + this.state.idArtist);

				if (this.state.isLoading === true) {
					this.fechData(this.state.idArtist);
					// console.log('this.fechData(this.state.idArtist): ' + this.state.idArtist);
				}
			}
			// }
		}

		if (this.props.route.params.typePlayer === "song") {
			// console.log('prevState.songId: ' + prevState.songId);
			// console.log('this.props.route.params.songId: ' + this.props.route.params.songId);

			// if (prevState.songId) {
			if (prevState.songId !== this.props.route.params.songId) {
				this.setState({
					songId: this.props.route.params.songId,
					typePlayer: "song",
					isLoading: true,
					controlsPN: false,
				});

				// console.log('songId: ' + this.state.songId);

				if (this.state.isLoading === true) {
					this.fechData(this.state.songId);
					// console.log('this.fechData(this.state.songId): ' + this.state.songId);
				}
			}
			// }
		}

		if (this.props.route.params.typePlayer === "playlist") {
			// console.log('prevState.playlistDetail: ' + prevState.playlistDetail);
			// console.log('this.props.route.params.playlistDetail: ' + this.props.route.params.playlistDetail);

			// if (prevState.playlistDetail) {
			if (prevState.playlistDetail !== this.props.route.params.playlistDetail) {
				this.setState({
					playlistDetail: this.props.route.params.playlistDetail,
					typePlayer: "playlist",
					isLoading: true,
					controlsPN: true,
				});

				// console.log('playlistDetail: ' + this.state.playlistDetail);

				if (this.state.isLoading === true) {
					this.fechData(this.state.playlistDetail);

					// console.log('this.fechData(this.state.playlistDetail): ' + this.state.playlistDetail);
				}
			}
			// }
		}

		if (this.props.route.params.typePlayer === "album") {
			// console.log('prevState.idAlbum: ' + prevState.idAlbum);
			// console.log('this.props.route.params.idAlbum: ' + this.props.route.params.idAlbum);

			// if (prevState.idAlbum) {
			// console.log('entro album');

			if (prevState.idAlbum !== this.props.route.params.idAlbum) {
				this.setState({
					idAlbum: this.props.route.params.idAlbum,
					typePlayer: "album",
					isLoading: true,
					controlsPN: true,
				});
				// console.log('idAlbum: ' + this.state.idAlbum);

				if (this.state.isLoading === true) {
					this.fechData(this.state.idAlbum);
					// console.log('this.fechData(this.state.idAlbum): ' + this.state.idAlbum);
				}
			}
			// }
		} //
	}

	_mountVideo = (component) => {
		// console.log('cargo 2');
		// playlistDetail: 'features',

		const { typePlayer, idArtist, songId, idAlbum, playlistDetail } = this.state;
		if (typePlayer === "artist") {
			this.fechData(idArtist);
		}

		if (typePlayer === "playlist") {
			this.fechData(playlistDetail);
		}

		if (typePlayer === "album") {
			this.fechData(idAlbum);
		}

		if (typePlayer === "song") {
			this.fechData(songId);
		}

		this._video = component;
	};

	fechData = async (idDataFech) => {
		// console.log('cargo 3');
		if (this.state.phoneHeight < 650) {
			this.setState({
				showVolume: false,
			});

			//console.log(this.state.phoneHeight);
		}

		let url = null;
		if (this.state.typePlayer === "artist") {
			url = "https://backend.nrwmusic.stream/api/" + idDataFech + "/songsartists";
			this.setState({ controlsPN: true });
			// console.log('artist: ' + url);
		}

		if (this.state.typePlayer === "song") {
			url = "https://backend.nrwmusic.stream/api/" + idDataFech + "/songs";
			this.setState({ controlsPN: false });
			// console.log('song: ' + url);
		}

		if (this.state.typePlayer === "playlist") {
			url = "https://backend.nrwmusic.stream/api/" + idDataFech;
			this.setState({ controlsPN: true });
			// console.log('playlist: ' + url);
		}

		if (this.state.typePlayer === "album") {
			url = "https://backend.nrwmusic.stream/api/" + idDataFech + "/tracksalbums";
			this.setState({ controlsPN: true });
			// console.log('album: ' + url);
		}

		let response = await fetch(url);
		let data = await response.json();
		this.setState({ song: data, isLoading: false });
		// console.log(data);

		//SomeComponent(this.state.song);

		this._loadNewPlaybackInstance(false);
	};

	componentDidMount() {
		// console.log('se monto');

		sessionStorage.setItem("playerMount", true);

		this._isMounted = true;
		Audio.setAudioModeAsync({
			allowsRecordingIOS: false,
			staysActiveInBackground: true,
			interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
			playsInSilentModeIOS: true,
			shouldDuckAndroid: true,
			interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
			playThroughEarpieceAndroid: false,
			playsInBackgroundModeIOS: true,
			playsInBackgroundModeAndroid: true,
		});
	}

	async _loadNewPlaybackInstance(playing) {
		if (this.playbackInstance != null) {
			await this.playbackInstance.unloadAsync();
			this.playbackInstance = null;
		}

		this._UpdateSongRecord();
		let urlsong = "https://backend.nrwmusic.stream/uploads/354fg6wGRd5s4J/" + this.state.song[this.index].urlsong;
		const source = { uri: urlsong };
		const initialStatus = {
			shouldPlay: playing,
			rate: this.state.rate,
			shouldCorrectPitch: this.state.shouldCorrectPitch,
			volume: this.state.volume,
			isMuted: this.state.muted,
			isLooping: this.state.loopingType === LOOPING_TYPE_ONE,
			// // UNCOMMENT THIS TO TEST THE OLD androidImplementation:
			// androidImplementation: 'MediaPlayer',
		};

		if (this.state.song[this.index].isVideo) {
			// console.log(this._onPlaybackStatusUpdate);
			await this._video.loadAsync(source, initialStatus);
			// this._video.onPlaybackStatusUpdate(this._onPlaybackStatusUpdate);
			this.playbackInstance = this._video;
			const status = await this._video.getStatusAsync();
		} else {
			const { sound, status } = await Audio.Sound.createAsync(source, initialStatus, this._onPlaybackStatusUpdate);
			this.playbackInstance = sound;
		}

		sessionStorage.setItem("songName", this.state.song[this.index].title);
		sessionStorage.setItem("albumName", this.state.song[this.index].album_title);
		sessionStorage.setItem("artistName", this.state.song[this.index].artist_name);
		sessionStorage.setItem("coverName", "https:" + this.state.song[this.index].cover);

		this._updateScreenForLoading(false);
	}

	_updateScreenForLoading(isLoading) {
		if (isLoading) {
			this.setState({
				showVideo: false,
				isPlaying: false,
				playbackInstanceName: LOADING_STRING,
				playbackInstanceDuration: null,
				playbackInstancePosition: null,
				isLoading: true,
			});
			// sessionStorage.setItem("isSongPlaying", this.state.isPlaying);

			if (this.state.isPlaying) {
				sessionStorage.setItem("imgIcon", "icon-lp.gif");
			} else {
				sessionStorage.setItem("imgIcon", "icon.jpg");
			}
		} else {
			this.setState({
				playbackInstanceName: this.state.song[this.index].name,
				showVideo: this.state.song[this.index].isVideo,
				isLoading: false,
			});
		}

		if (this.playbackInstance != null) {
			if (this.state.isPlaying) {
				sessionStorage.setItem("isSongPlaying", this.state.isPlaying);
				sessionStorage.setItem("imgIcon", "icon.jpg");
				this.playbackInstance.pauseAsync();
			} else {
				sessionStorage.setItem("isSongPlaying", this.state.isPlaying);
				sessionStorage.setItem("imgIcon", "icon-lp.gif");

				this.playbackInstance.playAsync();
			}
		}
	}

	_onPlaybackStatusUpdate = (status) => {
		if (status.isLoaded) {
			this.setState({
				playbackInstancePosition: status.positionMillis,
				playbackInstanceDuration: status.durationMillis,
				shouldPlay: status.shouldPlay,
				isPlaying: status.isPlaying,
				isBuffering: status.isBuffering,
				rate: status.rate,
				muted: status.isMuted,
				volume: status.volume,
				loopingType: status.isLooping ? LOOPING_TYPE_ONE : LOOPING_TYPE_ALL,
				shouldCorrectPitch: status.shouldCorrectPitch,
			});

			if (this.state.isPlaying) {
				sessionStorage.setItem("imgIcon", "icon-lp.gif");
			} else {
				sessionStorage.setItem("imgIcon", "icon.jpg");
			}

			sessionStorage.setItem("isSongPlaying", status.isPlaying);

			if (status.didJustFinish && !status.isLooping) {
				this._advanceIndex(true);
				this._updatePlaybackInstanceForIndex(true);
			}
		} else {
			if (status.error) {
				// console.log(`FATAL PLAYER ERROR: ${status.error}`);
			}
		}
	};

	_onLoadStart = () => {
		// console.log(`ON LOAD START`);
	};

	_onLoad = (status) => {
		// console.log(`ON LOAD : ${JSON.stringify(status)}`);
	};

	_onError = (error) => {
		// console.log(`ON ERROR : ${error}`);
	};

	_onReadyForDisplay = (event) => {
		const widestHeight = (DEVICE_WIDTH * event.naturalSize.height) / event.naturalSize.width;
		if (widestHeight > VIDEO_CONTAINER_HEIGHT) {
			this.setState({
				videoWidth: (VIDEO_CONTAINER_HEIGHT * event.naturalSize.width) / event.naturalSize.height,
				videoHeight: VIDEO_CONTAINER_HEIGHT,
			});
		} else {
			this.setState({
				videoWidth: DEVICE_WIDTH,
				videoHeight: (DEVICE_WIDTH * event.naturalSize.height) / event.naturalSize.width,
			});
		}
	};

	_onFullscreenUpdate = (event) => {
		// console.log(
		// 	`FULLSCREEN UPDATE : ${JSON.stringify(event.fullscreenUpdate)}`,
		// );
	};

	_advanceIndex(forward) {
		this.index = (this.index + (forward ? 1 : this.state.song.length - 1)) % this.state.song.length;
	}

	async _updatePlaybackInstanceForIndex(playing) {
		this._updateScreenForLoading(true);

		this.setState({
			videoWidth: DEVICE_WIDTH,
			videoHeight: VIDEO_CONTAINER_HEIGHT,
		});

		this._loadNewPlaybackInstance(playing);
	}

	_UpdateSongRecord = () => {
		let idup = this.state.song[this.index].id;

		this.setState({ songId: this.state.song[this.index].id });

		fetch("https://nrwmusic.stream/plays.php", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				song_id: idup,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				// Showing response message coming from server updating records.
				// Alert.alert(responseJson);
			})
			.catch((error) => {
				// console.error(error);
			});
	};

	_onPlayPausePressed = () => {
		if (this.playbackInstance != null) {
			if (this.state.isPlaying) {
				this.playbackInstance.pauseAsync();
			} else {
				this.playbackInstance.playAsync();
			}
		}
	};

	_onStopPressed = () => {
		if (this.playbackInstance != null) {
			this.playbackInstance.stopAsync();
		}
	};

	_onForwardPressed = () => {
		if (this.playbackInstance != null) {
			this._advanceIndex(true);
			this._updatePlaybackInstanceForIndex(this.state.shouldPlay);
		}
	};

	_onBackPressed = () => {
		if (this.playbackInstance != null) {
			this._advanceIndex(false);
			this._updatePlaybackInstanceForIndex(this.state.shouldPlay);
		}
	};

	_onMutePressed = () => {
		if (this.playbackInstance != null) {
			this.playbackInstance.setIsMutedAsync(!this.state.muted);
		}
	};

	_onLoopPressed = () => {
		if (this.playbackInstance != null) {
			this.playbackInstance.setIsLoopingAsync(this.state.loopingType !== LOOPING_TYPE_ONE);
		}
	};

	_onVolumeSliderValueChange = (value) => {
		if (this.playbackInstance != null) {
			this.playbackInstance.setVolumeAsync(value);
		}
	};

	_trySetRate = async (rate, shouldCorrectPitch) => {
		if (this.playbackInstance != null) {
			try {
				await this.playbackInstance.setRateAsync(rate, shouldCorrectPitch);
			} catch (error) {
				// Rate changing could not be performed, possibly because the client's Android API is too old.
			}
		}
	};

	_onRateSliderSlidingComplete = async (value) => {
		this._trySetRate(value * RATE_SCALE, this.state.shouldCorrectPitch);
	};

	_onPitchCorrectionPressed = async (value) => {
		this._trySetRate(this.state.rate, !this.state.shouldCorrectPitch);
	};

	_onSeekSliderValueChange = (value) => {
		if (this.playbackInstance != null && !this.isSeeking) {
			this.isSeeking = true;
			this.shouldPlayAtEndOfSeek = this.state.shouldPlay;
			this.playbackInstance.pauseAsync();
		}
	};

	_onSeekSliderSlidingComplete = async (value) => {
		if (this.playbackInstance != null) {
			this.isSeeking = false;
			const seekPosition = value * this.state.playbackInstanceDuration;
			if (this.shouldPlayAtEndOfSeek) {
				this.playbackInstance.playFromPositionAsync(seekPosition);
			} else {
				this.playbackInstance.setPositionAsync(seekPosition);
			}
		}
	};

	_getSeekSliderPosition() {
		if (this.playbackInstance != null && this.state.playbackInstancePosition != null && this.state.playbackInstanceDuration != null) {
			return this.state.playbackInstancePosition / this.state.playbackInstanceDuration;
		}
		return 0;
	}

	_getMMSSFromMillis(millis) {
		const totalSeconds = millis / 1000;
		const seconds = Math.floor(totalSeconds % 60);
		const minutes = Math.floor(totalSeconds / 60);

		const padWithZero = (number) => {
			const string = number.toString();
			if (number < 10) {
				return "0" + string;
			}
			return string;
		};
		return padWithZero(minutes) + ":" + padWithZero(seconds);
	}

	_getTimestamp() {
		if (this.playbackInstance != null && this.state.playbackInstancePosition != null && this.state.playbackInstanceDuration != null) {
			return `${this._getMMSSFromMillis(this.state.playbackInstancePosition)}`;
		}
		return "";
	}

	_getFullTimestamp() {
		if (this.playbackInstance != null && this.state.playbackInstancePosition != null && this.state.playbackInstanceDuration != null) {
			return `${this._getMMSSFromMillis(this.state.playbackInstanceDuration)}`;
		}
		return "";
	}

	_onPosterPressed = () => {
		this.setState({ poster: !this.state.poster });
	};

	_onUseNativeControlsPressed = () => {
		this.setState({ useNativeControls: !this.state.useNativeControls });
	};

	_onFullscreenPressed = () => {
		try {
			this._video.presentFullscreenPlayer();
		} catch (error) {
			// console.log(error.toString());
		}
	};

	_onSpeakerPressed = () => {
		this.setState(
			(state) => {
				return { throughEarpiece: !state.throughEarpiece };
			},
			({ throughEarpiece }) =>
				Audio.setAudioModeAsync({
					allowsRecordingIOS: false,
					interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
					playsInSilentModeIOS: true,
					shouldDuckAndroid: true,
					interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
					playThroughEarpieceAndroid: throughEarpiece,
				})
		);
	};

	render() {
		return !this.state.fontLoaded ? (
			<View style={styles.loading}>
				<Image style={[styles.loadingGif]} source={require("../assets/images/loading-nrw.gif")} />
			</View>
		) : (
			<LinearGradient colors={["#130810", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#2b1325", "#130810"]} style={styles.linearGradient}>
				<View style={styles.container}>
					{/* <View /> */}

					<ImageBackground resizeMode="cover" source={require("../assets/images/backColor.png")} style={styles.backColor}>
						<View style={styles.nameContainer}>
							<TouchableOpacity
								// onPress={() => this.props.navigation.goBack(null)}
								// onPress={() => {
								// 	this.props.navigation.navigate('Home', {
								// 		idArtist: this.state.idArtist,
								// 	});
								// 	}}
								onPress={() => {
									this.props.navigation.navigate("SongDetail", {
										idArtist: this.state.idArtist,
										songId: this.state.songId,
									});
								}}
								style={styles.btnContain}
								activeOpacity={0.85}
							>
								<Image style={[styles.btnImage]} source={require("../assets/images/icon-page-back.png")} />
							</TouchableOpacity>
						</View>
						<View style={styles.videoContainer}>
							{this.state.song ? (
								<View style={[{ width: "100%" }]}>
									<ImageBackground resizeMode="contain" source={require("../assets/images/backCircles.png")} style={styles.backCircles}>
										<Image
											style={styles.albumCover}
											source={{
												uri: "https:" + this.state.song[this.index].cover,
											}}
										/>
									</ImageBackground>
								</View>
							) : (
								<Text></Text>
							)}
							<Video
								ref={this._mountVideo}
								style={[
									styles.video,
									{
										opacity: 0.0,
										height: 0,
										width: 0,
									},
								]}
								resizeMode={Video.RESIZE_MODE_CONTAIN}
								onPlaybackStatusUpdate={this._onPlaybackStatusUpdate}
								onLoadStart={this._onLoadStart}
								onLoad={this._onLoad}
								onError={this._onError}
								onFullscreenUpdate={this._onFullscreenUpdate}
								onReadyForDisplay={this._onReadyForDisplay}
								useNativeControls={this.state.useNativeControls}
							/>
						</View>
						<View
							style={[
								styles.playbackContainer,
								{
									opacity: this.state.isLoading ? DISABLED_OPACITY : 1.0,
								},
							]}
						>
							{this.state.song ? (
								<View style={styles.trackInfo}>
									<Text numberOfLines={1} style={[styles.trackInfoText, styles.largeText]}>
										{this.state.song[this.index].title}
									</Text>

									<TouchableOpacity
										style={styles.buttonTopAlbum}
										activeOpacity={0.85}
										onPress={() => {
											this.props.navigation.navigate("Tracks", {
												idArtist: this.state.song[this.index].idartist,
												idAlbum: this.state.song[this.index].idalbum,
											});
										}}
									>
										<Text numberOfLines={1} style={[styles.trackInfoText, styles.mediumText]}>
											{this.state.song[this.index].album_title}
										</Text>
									</TouchableOpacity>

									<TouchableOpacity
										style={styles.itemsFeature}
										activeOpacity={0.85}
										onPress={() => {
											this.props.navigation.navigate("Artist", {
												idArtist: this.state.song[this.index].idartist,
											});
										}}
									>
										<Text numberOfLines={1} style={[styles.trackInfoText, styles.smallText]}>
											{this.state.song[this.index].artist_name}
										</Text>
									</TouchableOpacity>
								</View>
							) : (
								<Text></Text>
							)}
						</View>
						<View
							style={[
								styles.buttonsContainerBase,
								styles.buttonsContainerTopRow,
								{
									opacity: this.state.isLoading ? DISABLED_OPACITY : 1.0,
								},
							]}
						>
							<Slider style={styles.playbackSlider} minimumTrackTintColor={"#5C3EED"} maximumTrackTintColor={"#2A1C69"} thumbTintColor={"#d129f0"} value={this._getSeekSliderPosition()} onValueChange={this._onSeekSliderValueChange} onSlidingComplete={this._onSeekSliderSlidingComplete} disabled={this.state.isLoading} />
							<View style={styles.timestampRow}>
								<Text style={[styles.text, styles.buffering]}>{this._getTimestamp()}</Text>
								<Text style={[styles.text, styles.timestamp]}>{this._getFullTimestamp()}</Text>
							</View>
						</View>

						{/*********************** VOLUMEN ***********************/}

						<View
							style={[
								styles.buttonsContainerBase,
								styles.buttonsContainerMiddleRow,
								{
									opacity: this.state.isLoading ? DISABLED_OPACITY : 1.0,
								},
							]}
						>
							{this.state.controlsPN ? (
								<TouchableOpacity underlayColor={BACKGROUND_COLOR} style={styles.wrapper} onPress={this._onBackPressed} disabled={this.state.isLoading} activeOpacity={0.85}>
									<Image style={[styles.button, styles.classPrev]} source={require("../assets/images/player-icons/icon-prev.png")} />
								</TouchableOpacity>
							) : (
								<View />
							)}

							<TouchableOpacity underlayColor={BACKGROUND_COLOR} style={styles.wrapper} onPress={this._onPlayPausePressed} disabled={this.state.isLoading} activeOpacity={0.85}>
								{!this.state.isPlaying ? <Image source={require("../assets/images/player-icons/icon-play.png")} style={[styles.button, styles.classPlay]} /> : <Image source={require("../assets/images/player-icons/icon-pause.png")} style={[styles.button, styles.classPlay]} />}
							</TouchableOpacity>

							{this.state.controlsPN ? (
								<TouchableOpacity style={styles.wrapper} onPress={this._onForwardPressed} disabled={this.state.isLoading} activeOpacity={0.85}>
									<Image style={[styles.button, styles.classNext]} source={require("../assets/images/player-icons/icon-next.png")} />
								</TouchableOpacity>
							) : (
								<View />
							)}
						</View>

						<View style={[styles.buttonsContainerBase, styles.buttonsContainerBottomRow]}>
							<View style={styles.volumeContainer}>
								<TouchableOpacity
									underlayColor={BACKGROUND_COLOR}
									onPress={this._onMutePressed}
									activeOpacity={0.85}
									style={[
										styles.wrapper,
										{
											opacity: this.state.showVolume ? 1 : 0,
										},
									]}
								>
									{this.state.muted ? <Image source={require("../assets/images/player-icons/icon-muted.png")} style={[styles.button, styles.classMute]} /> : <Image source={require("../assets/images/player-icons/icon-unmuted.png")} style={[styles.button, styles.classMute]} />}
								</TouchableOpacity>

								<Slider
									minimumTrackTintColor={"#5C3EED"}
									maximumTrackTintColor={"#2A1C69"}
									thumbTintColor={"#d129f0"}
									value={1}
									onValueChange={this._onVolumeSliderValueChange}
									style={[
										styles.volumeSlider,
										{
											opacity: this.state.showVolume ? 1 : 0,
										},
									]}
								/>

								<TouchableOpacity
									style={[
										styles.wrapper,
										{
											opacity: this.state.showVolume ? 1 : 0,
										},
									]}
									onPress={this._onLoopPressed}
									activeOpacity={0.85}
								>
									<Image style={[styles.button, styles.classLoop]} source={LOOPING_TYPE_ICONS[this.state.loopingType].module} />
								</TouchableOpacity>
							</View>
						</View>

						<View />
					</ImageBackground>
				</View>

				{/* /////////////////////////// MENU /////////////////////////// */}
				<View style={styles.menuContain}>
					<TouchableOpacity onPress={() => this.props.navigation.navigate("Home")} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-home.png")} />
						<Text style={[styles.menuText]}>Home</Text>
					</TouchableOpacity>

					{/* <TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("Player");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-player.png")} />
						<Text style={[styles.menuText]}>Player</Text>
					</TouchableOpacity> */}

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("Library");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-library.png")} />
						<Text style={[styles.menuText]}>Library</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate("About");
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require("../assets/images/icon-about.png")} />
						<Text style={[styles.menuText]}>About</Text>
					</TouchableOpacity>
				</View>
				{/* /////////////////////////// MENU /////////////////////////// */}
			</LinearGradient>
		);
	}
}

const styles = StyleSheet.create({
	/////////////// LOADING ///////////////
	loadingGif: {
		width: 80,
		height: 12,
	},
	loading: {
		flex: 1,
		width: windowWidth,
		height: windowHeight,
		alignItems: "center",
		justifyContent: "center",
	},
	/////////////// LOADING ///////////////

	/////////////// MENU ///////////////
	menuText: {
		marginTop: 8,
		textAlign: "center",
		fontSize: 9,
		fontFamily: "Muli_400Regular",
		color: "#fff",
	},
	menuIconStyle: {
		height: 30,
		width: 30,
	},
	menuContain: {
		width: "100%",
		flexDirection: "row",
		alignItems: "flex-end",
		justifyContent: "space-around",
		borderWidth: 0,
		borderColor: "#22051a",
		backgroundColor: "#22051a",
	},
	menuBtnContain: {
		width: 30,
		marginTop: 8,
		marginBottom: 8,
	},
	menuBtnImage: {
		width: "100%",
		height: 30,
		alignItems: "center",
		justifyContent: "flex-end",
		resizeMode: "contain",
	},
	alignMenu: {
		flex: 1,
		borderWidth: 0,
		borderColor: "#22051a",
		width: "100%",
		padding: 8,
		flexDirection: "row",
		alignItems: "flex-end",
		justifyContent: "space-around",
		margin: 0,
	},
	/////////////// MENU ///////////////

	//
	linearGradient: {
		// flex: 1,
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		flex: 1,
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	backColor: {
		zIndex: -1,
		width: "100%",
		// height: '50%',
		// minWidth: DEVICE_WIDTH / 1.5,
		// maxWidth: DEVICE_WIDTH / 1.5,
		minHeight: DEVICE_HEIGHT - 90,
		maxHeight: DEVICE_HEIGHT - 90,
		flex: 1,
		top: 0,
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "space-between",
	},

	backCircles: {
		marginTop: -20,
		width: "100%",
		height: 330,
		// minHeight: DEVICE_WIDTH / 1.4,
		maxHeight: DEVICE_WIDTH / 0.8,
		top: 0,
		justifyContent: "center",
		alignItems: "center",
	},
	albumCover: {
		width: 180,
		height: 180,
		// minWidth: DEVICE_WIDTH / 2.0,
		maxWidth: DEVICE_WIDTH / 1.3,
		// minHeight: DEVICE_WIDTH / 2.0,
		maxHeight: DEVICE_WIDTH / 1.3,
		borderRadius: 10000000,
	},
	trackInfo: {
		marginTop: -30,
		marginBottom: 30,
		paddingLeft: 20,
		paddingRight: 20,
		height: 70,
		width: "100%",
	},

	trackInfoText: {
		textAlign: "center",
		flexWrap: "wrap",
		color: "#fff",
		fontFamily: "Muli_700Bold",
	},
	//
	largeText: {
		fontSize: 22,
		minHeight: 26,
		fontFamily: "Muli_900Black",
		color: "#fff",
	},
	mediumText: {
		fontSize: 18,
		minHeight: 22,
		marginTop: 5,
		marginBottom: 8,
		fontFamily: "Muli_700Bold",
		color: "#fff",
	},
	smallText: {
		fontSize: 14,
		minHeight: 18,
		fontFamily: "Muli_400Regular",
		color: "#abaaaa",
	},

	IconStyle: {
		padding: 10,
		margin: 5,
		marginTop: 40,
		height: 15,
		width: 25,
		resizeMode: "stretch",
	},
	PlayIconStyle: {
		padding: 5,
		margin: 0,
		height: 65,
		width: 65,
		resizeMode: "stretch",
	},

	controls: {
		flexDirection: "row",
	},
	//
	titlesSection: {
		zIndex: 10,
		padding: 13,
		width: "100%",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		alignSelf: "flex-start",
	},

	btnContain: {
		width: 40,
		height: 40,
		marginTop: 20,
		marginLeft: 20,
		justifyContent: "flex-start",
	},

	btnImage: {
		width: "100%",
		height: 40,
		alignItems: "center",
		justifyContent: "flex-end",
		resizeMode: "contain",
		marginRight: 20,
	},
	// ///////////////////////////////////
	// ///////////////////////////////////

	// /////////// ICONS///////////////
	classPlay: {
		width: 60,
		height: 60,
		margin: 5,
	},
	classPause: {
		width: 60,
		height: 60,
		margin: 5,
	},
	classStop: {
		width: 30,
		height: 30,
		margin: 5,
	},
	classNext: {
		width: 30,
		height: 30,
		margin: 5,
	},
	classPrev: {
		width: 30,
		height: 30,
		margin: 5,
	},

	classLoop: {
		width: 30,
		height: 30,
		marginTop: 5,
		marginBottom: 5,
		marginLeft: 15,
		marginRight: 5,
	},

	classMute: {
		width: 30,
		height: 30,
		margin: 5,
	},

	classTrack: {
		width: 166,
		height: 5,
	},

	classThumb: {
		width: 18,
		height: 22,
	},

	// ///////////////////////////////////

	emptyContainer: {
		alignSelf: "stretch",
		backgroundColor: BACKGROUND_COLOR,
	},
	container: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		alignSelf: "stretch",
	},
	wrapper: {},
	nameContainer: {
		height: 60,
		width: "100%",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},

	videoContainer: {
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		marginTop: -20,
	},
	video: {
		maxWidth: 0,
	},
	playbackContainer: {
		width: "100%",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		minHeight: 70,
	},
	playbackSlider: {
		alignSelf: "stretch",
	},
	timestampRow: {
		// flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		alignSelf: "stretch",
		minHeight: FONT_SIZE + 15,
		width: "100%",
		// paddingLeft: 20,
		// paddingRight: 20,
	},
	text: {
		fontSize: FONT_SIZE,
		minHeight: FONT_SIZE + 15,
		fontFamily: "Muli_400Regular",
		color: "#ABAAAA",
	},
	buffering: {
		textAlign: "left",
		marginTop: 20,

		// paddingLeft: 20,
		minWidth: DEVICE_WIDTH / 2.3,
		maxWidth: DEVICE_WIDTH / 2.3,
	},
	timestamp: {
		textAlign: "right",
		marginTop: 20,

		// paddingRight: 20,
		minWidth: DEVICE_WIDTH / 2.3,
		maxWidth: DEVICE_WIDTH / 2.3,
	},
	button: {
		// backgroundColor: BACKGROUND_COLOR,
		//   borderWidth: 1,
		//   borderColor: "black",
		//   borderStyle: "solid",
		width: 70,
		height: 20,
	},
	buttonsContainerBase: {
		// flex: 1,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		paddingLeft: 20,
		paddingRight: 20,
	},
	buttonsContainerTopRow: {
		// backgroundColor: 'red',

		marginBottom: 10,
		maxHeight: ICON_PLAY_BUTTON.height,
		// minWidth: DEVICE_WIDTH / 2.0,
		// maxWidth: DEVICE_WIDTH / 2.0
		minWidth: DEVICE_WIDTH,
		maxWidth: DEVICE_WIDTH,
		paddingLeft: 20,
		paddingRight: 20,
	},
	buttonsContainerMiddleRow: {
		// maxHeight: ICON_MUTED_BUTTON.height,
		// flex: 1,
		alignSelf: "stretch",
		paddingLeft: 20,
		paddingRight: 20,
		maxHeight: 75,
		marginTop: -50,
		marginBottom: -10,
		width: "100%",
		// backgroundColor: 'red',
		//
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	volumeContainer: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10,
		// paddingRight: 20,
		// paddingLeft: 20,
		// minWidth: DEVICE_WIDTH / 2.0,
		// maxWidth: DEVICE_WIDTH / 2.0,
	},
	volumeSlider: {
		width: "70%",
		// width: DEVICE_WIDTH / 2.0 - ICON_MUTED_BUTTON.width,
	},
	buttonsContainerBottomRow: {
		maxHeight: ICON_THUMB_1.height,
		alignSelf: "stretch",
		paddingRight: 20,
		paddingLeft: 20,
	},
	rateSlider: {
		width: DEVICE_WIDTH / 2.0,
	},
	buttonsContainerTextRow: {
		maxHeight: FONT_SIZE,
		alignItems: "center",
		paddingRight: 20,
		paddingLeft: 20,
		minWidth: DEVICE_WIDTH,
		maxWidth: DEVICE_WIDTH,
	},
});
