import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React from "react";
import { StyleSheet, TouchableOpacity, View, Image, Platform } from "react-native";
// import TabBarIcon from '../components/TabBarIcon';
// import MainScreen from '../screens/MainScreen';
import HomeScreen from "../screens/HomeScreen";
import AboutScreen from "../screens/AboutScreen";
import PlayerScreen from "../screens/PlayerScreen";
import ArtistScreen from "../screens/ArtistScreen";
import SongDetailScreen from "../screens/SongDetailScreen";
import FeaturesScreen from "../screens/FeaturesScreen";
import ExplorerScreen from "../screens/ExplorerScreen";
import LinksScreen from "../screens/LinksScreen";
import BioArtistScreen from "../screens/BioArtistScreen";
import TracksScreen from "../screens/TracksScreen";
import LibraryScreen from "../screens/LibraryScreen";
import ListArtistScreen from "../screens/ListArtistScreen";
import ListAlbumScreen from "../screens/ListAlbumScreen";
import ListSongScreen from "../screens/ListSongScreen";
import MiniPlayer from "../screens/MiniPlayer";

//// New
// import { StyleSheet, Image, Platform } from 'react-native';
//// New

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = "Home";

// BottomTab.navigationOptions = (navigation) => {
// 	let { routeName } = navigation.state.routes[navigation.state.index];
// 	let tabBarVisible = true;
// 	if (routeName === 'Home') {
// 		tabBarVisible = false;
// 	}
// 	return tabBarVisible;
// }

export default function BottomTabNavigator({ navigation, route }) {
	// Set the header title on the parent stack navigator depending on the
	// currently active tab. Learn more in the documentation:
	// https://reactnavigation.org/docs/en/screen-options-resolution.html
	navigation.setOptions({
		headerTitle: getHeaderTitle(route),
		headerShown: false,
		headerTintColor: "#22051a",
		headerStyle: { backgroundColor: "#22051a" },
	});

	BottomTab.navigationOptions = ({ navigation }) => {
		const routeName = oute.state.routes[route.state.index].name;
		let tabBarVisible = true;
		// console.log(routeName);
		// let routeName = navigation.state.routes[navigation.state.index].routeName;
		if (routeName == "Home") {
			tabBarVisible = false;
		}
		return { tabBarVisible };
	};

	return (
		// <View>
		// 	<TouchableOpacity
		// 		onPress={() => { this.props.navigation.navigate('Features') }}
		// 		// style={styles.btnContain}
		// 		activeOpacity={0.85}
		// 	>
		// 		<Image
		// 			// style={[styles.btnImage]}
		// 			source={require('../assets/images/icon-page-back.svg')}
		// 		/>
		// 	</TouchableOpacity>

		<BottomTab.Navigator
			initialRouteName={INITIAL_ROUTE_NAME}
			tabBarOptions={{
				activeTintColor: "#fff",
				inactiveTintColor: "#abaaaa",
				style: {
					backgroundColor: "#22051a",
					height: Platform.OS === "ios" ? 94 : 60,
					borderTopWidth: 0,
					display: "none",
				},
				labelStyle: {
					fontFamily: "Muli_400Regular",
					fontSize: 12,
					lineHeight: 14,
					textAlign: "center",
				},
			}}
		>
			<BottomTab.Screen
				name="SongDetail"
				component={SongDetailScreen}
				options={{
					title: "SongDetail",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Home"
				component={HomeScreen}
				options={{
					title: "Home",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="MiniPlayer"
				component={MiniPlayer}
				options={{
					title: "MiniPlayer",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Library"
				component={LibraryScreen}
				options={{
					title: "Library",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="ListArtist"
				component={ListArtistScreen}
				options={{
					title: "ListArtist",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="ListAlbum"
				component={ListAlbumScreen}
				options={{
					title: "ListAlbum",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="ListSong"
				component={ListSongScreen}
				options={{
					title: "ListSong",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="About"
				component={AboutScreen}
				options={{
					title: "About",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Features"
				component={FeaturesScreen}
				options={{
					title: "Features",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Explorer"
				component={ExplorerScreen}
				options={{
					title: "Explorer",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="BioArtist"
				component={BioArtistScreen}
				options={{
					title: "BioArtist",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Artist"
				component={ArtistScreen}
				options={{
					title: "Artist",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-home.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Player"
				component={PlayerScreen}
				options={{
					title: "Player",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-player.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Tracks"
				component={TracksScreen}
				options={{
					title: "Tracks",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-player.png")} style={styles.IconStyle} />,
				}}
			/>
			<BottomTab.Screen
				name="Links"
				component={LinksScreen}
				options={{
					title: "Resources",
					tabBarIcon: ({ focused }) => <Image source={require("../assets/images/icon-player.png")} style={styles.IconStyle} />,
				}}
			/>
		</BottomTab.Navigator>
		// </View>
	);
}

function getHeaderTitle(route) {
	const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;

	switch (routeName) {
		case "Home":
			return "How to get started";
		// case 'Main':
		// 	return 'How to get started';
		case "About":
			return "Links to learn more";
		case "Player":
			return "Links to learn more";
		case "Songs":
			return "Links to learn more";
		case "Artist":
			return "Links to learn more";
		case "MiniPlayer":
			return "Links to learn more";
		// case 'PlayerDetail':
		// 	return 'Links to learn more';
		case "Links":
			return "Links to learn more";
		case "BioArtist":
			return "Links to learn more";
		case "Explorer":
			return "Links to learn more";
		case "Library":
			return "Links to learn more";
		case "SongDetail":
			return "Links to learn more";
	}
}

const styles = StyleSheet.create({
	IconStyle: {
		margin: 5,
		height: 25,
		width: 25,
		// resizeMode: 'contain',
	},
});
