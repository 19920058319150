{
	/* expo install expo-screen-orientation */
}

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { Platform, StatusBar, StyleSheet, View } from "react-native";
import useCachedResources from "./hooks/useCachedResources";
import BottomTabNavigator from "./navigation/BottomTabNavigator";
import LinkingConfiguration from "./navigation/LinkingConfiguration";
// import { BrowserRouter, Switch, Route } from "react-router-dom";

// import { useKeepAwake } from "expo-keep-awake";

//// New ////
import { useFonts, Muli_300Light, Muli_400Regular, Muli_700Bold, Muli_900Black } from "@expo-google-fonts/muli";
//// New ////

const Stack = createStackNavigator();

export default function App(props) {
	// useKeepAwake();

	let [fontsLoaded] = useFonts({
		Muli_300Light,
		Muli_400Regular,
		Muli_700Bold,
		Muli_900Black,
	});

	global.isPlaying = false;
	const isLoadingComplete = useCachedResources();

	if (!fontsLoaded) {
		return null;
	} else {
		if (!isLoadingComplete) {
			return null;
		} else {
			return (
				<View style={styles.container}>
					{Platform.OS === "ios" && <StatusBar barStyle="light-content" backgroundColor="#2b1325" />}
					{Platform.OS === "android" && <StatusBar barStyle="light-content" backgroundColor="#2b1325" />}
					{Platform.OS === "default" && <StatusBar barStyle="light-content" backgroundColor="#2b1325" />}
					{/* <Menu linking={LinkingConfiguration} style={styles.alignMenu}></Menu> */}
					<NavigationContainer linking={LinkingConfiguration}>
						<Stack.Navigator>
							<Stack.Screen name="Root" component={BottomTabNavigator} />
							{/* {MiniPlayer} */}
							{/* <Stack.Screen
								name="MiniPlayer"
								component={MiniPlayer}
							/> */}
							{/* <BrowserRouter> */}
							{/* <Switch> */}
							{/* <Route
										path="/MiniPlayer"
										component={MiniPlayer}
									/> */}
							{/* </Switch> */}
							{/* </BrowserRouter> */}
						</Stack.Navigator>
					</NavigationContainer>
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
	container: {
		// width: '100%',
		...Platform.select({
			ios: {
				width: "100%",
			},
			android: {
				width: "100%",
			},
			default: {
				width: "100vw",
			},
		}),
		// height: '100%',
		...Platform.select({
			ios: {
				height: "100%",
			},
			android: {
				height: "100%",
			},
			default: {
				height: "100vh",
			},
		}),

		flex: 1,
		backgroundColor: "#22051a",
		flexDirection: "column-reverse",
		borderWidth: 0,
		borderColor: "#22051a",
	},
});
