import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, View, Image, Text, Dimensions, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { ScrollView } from 'react-native-gesture-handler';
import * as WebBrowser from 'expo-web-browser';
import MiniPlayer from './MiniPlayer';

const { width } = Dimensions.get('window');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class App extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		idArtist: this.props.route.params.idArtist,
		currentIndex: 0,
		isLoading: true,
		artist: [],
		gallery: [],
		playerMount: false,
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.idArtist !== this.props.route.params.idArtist) {
			this.setState({ idArtist: this.props.route.params.idArtist, isLoading: true });
			if (this.state.isLoading === true) {
				this.fechArtist(this.state.idArtist);
			}
		}
	}

	async fechArtist(idArtist) {
		const url = 'https://backend.nrwmusic.stream/api/' + idArtist + '/gallery';
		const response = await fetch(url);
		const dataGallery = await response.json();
		this.setState({ gallery: dataGallery, isLoading: false });

		const urlArtists = 'https://backend.nrwmusic.stream/api/' + idArtist + '/artists';
		const responseArtists = await fetch(urlArtists);
		const dataArtists = await responseArtists.json();
		this.setState({ artist: dataArtists });
		// console.log(this.state.artist[0].name);
	}

	async componentDidMount() {
		this.fechArtist(this.state.idArtist);
	}

	_handlePressTwitter = async () => {
		let twitterR = await WebBrowser.openBrowserAsync(this.state.artist[0].twitter);
		this.setState({ twitterR });
	};

	_handlePressFacebook = async () => {
		let facebookR = await WebBrowser.openBrowserAsync(this.state.artist[0].facebook);
		this.setState({ facebookR });
	};
	_handlePressInstagram = async () => {
		let instagramR = await WebBrowser.openBrowserAsync(this.state.artist[0].instagram);
		this.setState({ instagramR });
	};

	_handlePressYoutube = async () => {
		let youtubeR = await WebBrowser.openBrowserAsync(this.state.artist[0].youtube);
		this.setState({ youtubeR });
	};

	_handlePressSoundcloud = async () => {
		let soundcloudR = await WebBrowser.openBrowserAsync(this.state.artist[0].soundcloud);
		this.setState({ soundcloudR });
	};

	_handleArtistName = async () => {
		let artistName = this.state.artist[0].name;
		this.setState({ artistName });
	};

	_handleArtistBio = async () => {
		let artistBio = this.state.artist[0].biography;
		this.setState({ artistBio });
	};

	renderBio() {
		const { isLoading, artist } = this.state;
		if (isLoading === false && artist.length > 0) {
			return (
				<View style={styles.alignSection}>
					<Text style={styles.titleText}>{artist[0].name}</Text>
					<Text style={styles.bioText}>{artist[0].biography}</Text>
				</View>
			);
		}
	}

	onPressPlayer = () => {
		if (sessionStorage.getItem('playerMount')) {
			this.props.navigation.navigate('Player');
		} else {
			this.props.navigation.navigate('Home');
		}
	};

	render() {
		/////////////////////////// GALLERY ///////////////////////////
		const renderGallery = this.state.gallery.map((item) => {
			return (
				<View style={styles.itemsGallery} key={item.id}>
					<View style={styles.containerGallery}>
						<Image style={styles.galleryPhoto} source={{ uri: 'https:' + item.photo }} />
					</View>
				</View>
			);
		});
		/////////////////////////// GALLERY ///////////////////////////

		// const { state, goBack } = this.props.navigation;
		// const params = state.params || {};
		// idArtist

		return (
			<LinearGradient colors={['#130810', '#2b1325', '#2b1325', '#2b1325', '#2b1325', '#2b1325', '#130810']} style={styles.linearGradient}>
				<View style={styles.statusBarColor}></View>
				<ScrollView style={styles.scrollVertical} vertical={true} showsHorizontalScrollIndicator={false} scrollEventThrottle={200} decelerationRate="fast">
					<View style={styles.container}>
						{this.state.isLoading || !this.state.gallery ? (
							<View style={styles.loading}>
								<Image style={[styles.loadingGif]} source={require('../assets/images/loading-nrw.gif')} />
							</View>
						) : (
							<Text></Text>
						)}

						<LinearGradient colors={['black', 'rgba(0, 0, 0, 0)']} style={styles.bannerGradient}>
							<TouchableOpacity
								// onPress={() => this.props.navigation.goBack()}
								onPress={() => {
									this.props.navigation.navigate('Artist', {
										idArtist: this.state.idArtist,
									});
								}}
								style={styles.btnContain}
								activeOpacity={0.85}
							>
								<Image style={[styles.btnImage]} source={require('../assets/images/icon-page-back.png')} />
							</TouchableOpacity>
						</LinearGradient>
						{/* ****************** GALLERY ****************** */}
						<View style={styles.sliderContainer}>
							<ScrollView
								style={styles.scrollGalleryHorizontal}
								horizontal={true}
								showsHorizontalScrollIndicator={false}
								scrollEventThrottle={200}
								// decelerationRate="slow"
								pagingEnabled
								iscrollIndicatorInsets
							>
								{renderGallery}
							</ScrollView>
						</View>
						<View style={styles.containerGalleryIcon}>
							<Image style={styles.galleryIcon} source={require('../assets/images/icon-slideshow.png')} />
						</View>
						{/* ****************** GALLERY ****************** */}

						{/* ****************** BIO ****************** */}

						<View style={styles.container}>{this.renderBio()}</View>
						<View style={styles.container}>
							<View style={styles.socialSection}>
								<TouchableOpacity onPress={this._handlePressTwitter} style={styles.btnSocialIcon} activeOpacity={0.85}>
									<Image style={styles.socialIcon} source={require('../assets/images/social-twitter.png')} />
								</TouchableOpacity>

								<TouchableOpacity onPress={this._handlePressFacebook} style={styles.btnSocialIcon} activeOpacity={0.85}>
									<Image style={styles.socialIcon} source={require('../assets/images/social-facebook.png')} />
								</TouchableOpacity>

								<TouchableOpacity onPress={this._handlePressInstagram} style={styles.btnSocialIcon} activeOpacity={0.85}>
									<Image style={styles.socialIcon} source={require('../assets/images/social-instagram.png')} />
								</TouchableOpacity>

								<TouchableOpacity onPress={this._handlePressYoutube} style={styles.btnSocialIcon} activeOpacity={0.85}>
									<Image style={styles.socialIcon} source={require('../assets/images/social-youtube.png')} />
								</TouchableOpacity>

								<TouchableOpacity onPress={this._handlePressSoundcloud} style={styles.btnSocialIcon} activeOpacity={0.85}>
									<Image style={styles.socialIcon} source={require('../assets/images/social-soundcloud.png')} />
								</TouchableOpacity>
							</View>
						</View>
						{/* ****************** BIO ****************** */}
					</View>
				</ScrollView>

				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}
				<MiniPlayer />
				{/* /////////////////////////// MINI PLAYER /////////////////////////// */}

				{/* /////////////////////////// MENU /////////////////////////// */}
				<View style={styles.menuContain}>
					<TouchableOpacity onPress={() => this.props.navigation.navigate('Home')} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require('../assets/images/icon-home.png')} />
						<Text style={[styles.menuText]}>Home</Text>
					</TouchableOpacity>

					<TouchableOpacity onPress={() => this.onPressPlayer()} style={styles.menuBtnContain} activeOpacity={0.85}>
						<Image style={[styles.menuIconStyle]} source={require('../assets/images/icon-player.png')} />
						<Text style={[styles.menuText]}>Player</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate('Library');
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require('../assets/images/icon-library.png')} />
						<Text style={[styles.menuText]}>Library</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() => {
							this.props.navigation.navigate('About');
						}}
						style={styles.menuBtnContain}
						activeOpacity={0.85}
					>
						<Image style={[styles.menuIconStyle]} source={require('../assets/images/icon-about.png')} />
						<Text style={[styles.menuText]}>About</Text>
					</TouchableOpacity>
				</View>
				{/* /////////////////////////// MENU /////////////////////////// */}
			</LinearGradient>
		);
	}
}

const styles = StyleSheet.create({
	/////////////// STATUS BAR ///////////////
	statusBarColor: {
		position: 'absolute',
		top: 0,
		width: '100%',
		// height: Platform.OS === 'ios' ? 21 : 0,
		...Platform.select({
			ios: {
				height: 21,
			},
			android: {
				height: 0,
			},
			default: {
				height: 0,
			},
		}),
		backgroundColor: '#190914',
		zIndex: 999,
	},
	/////////////// STATUS BAR ///////////////
	/////////////// LOADING ///////////////
	loadingGif: {
		width: 80,
		height: 12,
	},
	loading: {
		flex: 1,
		width: windowWidth,
		height: windowHeight,
		alignItems: 'center',
		justifyContent: 'center',
	},
	/////////////// LOADING ///////////////
	/////////////// SLIDER ///////////////
	containerGalleryIcon: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 999,
		marginTop: -35,
	},
	galleryIcon: {
		width: 47,
		height: 30,
		opacity: 0.85,
	},
	bannerGradient: {
		// flex: 1,
		top: -10,
		// paddingTop: Platform.OS === 'ios' ? 40 : 10,
		...Platform.select({
			ios: {
				paddingTop: 40,
			},
			android: {
				paddingTop: 10,
			},
			default: {
				paddingTop: 20,
			},
		}),
		width: '100%',
		height: 90,
		alignItems: 'flex-start',
		// justifyContent: 'center',
		justifyContent: 'flex-start',
		zIndex: 9,
		position: 'absolute',
	},
	sliderContainer: {
		position: 'relative',
		width: '100%',
		margin: 0,
		marginTop: -20,
		zIndex: 1,
	},
	btnContain: {
		width: 30,
		height: 30,
		marginLeft: 10,
		marginTop: 14,
		marginBottom: -50,
		justifyContent: 'flex-start',
		zIndex: 9,
	},
	btnImage: {
		width: '100%',
		height: 30,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
	},
	scrollGalleryHorizontal: {
		width: '100%',
		margin: 0,
		zIndex: 1,
	},
	containerGallery: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		// paddingTop: 20,
	},
	scrollContainer: {
		//   height,
		height: 300,
	},
	itemsGallery: {
		width,
		height: 300,
	},
	// galleryPhoto: {
	// 	width,
	// 	height,
	// },
	galleryPhoto: {
		width: '100%',
		height: 300,
	},
	/////////////// SLIDER ///////////////

	/////////////// SOCIAL ///////////////
	socialSection: {
		padding: 13,
		marginBottom: 40,
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	btnSocialIcon: {
		width: 30,
		height: 30,
		marginTop: 10,
		marginRight: 20,
		// marginBottom: -50,
		justifyContent: 'flex-start',
		zIndex: 9,
	},
	socialIcon: {
		width: '100%',
		height: 30,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
	},
	/////////////// SOCIAL ///////////////
	scrollVertical: {
		flex: 1,
		width: '100%',
	},
	linearGradient: {
		height: '100%',
		flex: 1,
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		// flex: 1,
		width: '100%',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	buttoncont: {
		marginTop: -30,
		marginBottom: 20,
		width: 'fit-content',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	button: {
		width: 124,
		height: 35,
	},
	buttonimg: {
		width: 124,
		height: 35,
		resizeMode: 'contain',
	},
	titlesSection: {
		marginTop: 10,
		resizeMode: 'contain',
	},
	backColor: {
		paddingTop: 10,
		width: '100%',
		flex: 1,
		top: 0,
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	backbanner: {
		width: '100%',
		height: 200,
		top: -30,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	backprofile: {
		width: 110,
		height: 110,
		top: -85,
		marginBottom: -40,
		justifyContent: 'center',
		alignItems: 'center',
	},
	backprofilecont: {
		width: 80,
		height: 80,
		borderRadius: 300,
	},
	backCircles: {
		width: '100%',
		flex: 1,
		top: 0,
		justifyContent: 'center',
		alignItems: 'center',
	},
	bannerCover: {
		width: '100%',
		height: 200,
	},
	albumCover: {
		width: 220,
		height: 220,
		borderRadius: 300,
	},
	trackInfo: {
		// padding: 20,
	},
	trackInfoText: {
		textAlign: 'center',
		flexWrap: 'wrap',
		color: '#fff',
		fontFamily: 'Muli_700Bold',
	},
	nameArtist: {
		textAlign: 'center',
		flexWrap: 'wrap',
		color: 'red',
		// marginTop: -80,
		fontFamily: 'Muli_700Bold',
	},
	//////////// TOP SONGS ////////////
	alignSection: {
		padding: 13,
		marginBottom: 5,
		width: '100%',
		// flexDirection: 'row',
		// justifyContent: 'space-between',
	},
	titleText: {
		width: '100%',
		marginTop: 10,
		marginBottom: 10,
		fontSize: 24,
		textAlign: 'left',
		fontFamily: 'Muli_700Bold',
		color: '#e35ce6',
	},
	bioText: {
		textAlign: 'left',
		color: '#fff',
		fontSize: 16,
		marginBottom: 20,
		fontFamily: 'Muli_400Regular',
	},

	/////////////// MENU ///////////////
	menuText: {
		marginTop: 8,
		textAlign: 'center',
		fontSize: 9,
		fontFamily: 'Muli_400Regular',
		color: '#fff',
	},
	menuIconStyle: {
		height: 30,
		width: 30,
	},
	menuContain: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		borderWidth: 0,
		borderColor: '#22051a',
		backgroundColor: '#22051a',
	},
	menuBtnContain: {
		width: 30,
		marginTop: 8,
		marginBottom: 8,
	},
	menuBtnImage: {
		width: '100%',
		height: 30,
		alignItems: 'center',
		justifyContent: 'flex-end',
		resizeMode: 'contain',
	},
	alignMenu: {
		flex: 1,
		borderWidth: 0,
		borderColor: '#22051a',
		width: '100%',
		padding: 8,
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		margin: 0,
	},
	/////////////// MENU ///////////////
});
